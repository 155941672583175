import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

// import the roots
import rootReducer from './redux-sagas/reducers/index';
import rootSaga from './redux-sagas/sagas/root';

const sagaMiddleware = createSagaMiddleware()

const defaultState = {
    cart: localStorage.getItem('sportslogic.cart') ? JSON.parse(localStorage.getItem('sportslogic.cart')) : [],    
    coach_profile: null,
    coaches: null,
    collaborators: null,
    communities: null,
    countries: null,
    custom_pages: null,
    evaluation: null,
    evaluations: null,
    family: null,
    follow_levels: null,
    follow_seasons: null,
    followees: null,
    folders: null,
    folder: null,
    form: null,
    information: null,
    is_cart_open: null,
    league: null,
    leagues: null,
    loading: null,
    match: null,
    notes: null,
    penalties: null,
    plans: null,
    player: null,
    players: null,
    pouch: localStorage.getItem('sportslogic.pouch') ? JSON.parse(localStorage.getItem('sportslogic.pouch')) : {},
    prospects: null,
    public_matches: null,
    questionaries: null,
    registrations: null,
    roster: null,
    scout_match: null,
    states: null,
    subscriptions: null,
    team: null,
    teams: null,
    topics: null,
    user: null,
    video: null,
    videos: null
};

// TODO: understand this ?
const enhancers = compose(
    applyMiddleware(sagaMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
);
const store = createStore(
    rootReducer,
    defaultState,
    enhancers
);
//

sagaMiddleware.run(rootSaga)

export default store;
