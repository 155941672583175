//
import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import { humanize_user } from '../../helpers';
import request from 'superagent';


export function* fetch_custom_pages() {
    yield* takeEvery("CUSTOM_PAGES/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/custom_pages/all`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { custom_pages } = result;
                yield put({ type: 'CUSTOM_PAGES/SET', custom_pages });
            } else {
                yield put({
                    type: 'CUSTOM_PAGES/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'CUSTOM_PAGES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_public_match_reports() {
    yield* takeEvery("MATCH_REPORTS/PUBLIC/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/public_player_matches`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { matches } = result;                
                yield put({ type: 'PUBLIC_MATCHES/SET', public_matches: matches });
            } else {
                yield put({
                    type: 'PUBLIC_MATCHES/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'PUBLIC_MATCHES/FETCH_FAILED',
                result: e
            });
        }
    });
}