function seasons(state = [], action) {
    switch (action.type) {
        case 'SEASONS/SET':
            return action.seasons;        
        case 'SEASONS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default seasons;
