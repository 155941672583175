import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import SelectableUser from '../../common/SelectableUser';
import SelectableTeamRole from '../../common/SelectableTeamRole';
import _ from 'lodash';

class RegistrationPlayerSelection extends React.Component {

    state = {
        step: 1,
        mode: 0,
        bootstrapped: true,
        alreadyRegister: [],
        selectedUser: null,
        searchTerm: null,
        coachRoles: [
            {
                Id: 4,
                Name: 'Coach',
                selected: true
            },
            {
                Id: 4,
                Name: 'Assistant Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Team Manager',
                selected: false
            },
        ]
    }

    selectMode = (mode) => {
        this.setState({
            mode,
            step: 2
        });
    }

    selectUser = (user) => {
        const {familyRegistration, cart, season, league} = this.props, { mode } = this.state;

        // This awful routine is to determine if the user is already registered in the selected mode / season
        var inCart = false,
            registered = false;

        var filteredFamilyRegistration = _.filter(familyRegistration, (fr) => {
            return ((fr.IdRole === 4 && mode === 2) || (fr.IdRole === 3 && mode === 1));
        });

        if (filteredFamilyRegistration && season) {
            registered = _.find(familyRegistration, (fr) => {
                return fr.IdSeason === season.IdSeason && fr.IdUser === user.IdUser && ((fr.IdRole === 3 && mode === 1) || (fr.IdRole !== 3 && mode === 2));
            });
        }
        if (cart && cart.length) {
            inCart = _.find(cart, (item) => {
                return item.selectedUser.IdUser === user.IdUser
                    && item.selectedSeason.IdSeason === season.IdSeason && !item.selectedTeam
                    && ((!item.selectedRole && mode === 1) || (item.selectedRole && mode === 2))
            }) ? true : false;
        } //

        if (!inCart && !registered) {

            if (mode === 1) {


                // TODO: 'persist' selected options and go to the next step
                this.props.fnOk && this.props.fnOk({
                    selectedUser: user,
                    mode: mode,
                    selectedSeason: season,
                    selectedLeague: league
                });

            } else {

                this.setState({
                    selectedUser: user,
                    step: 3
                });
            }
        }
    }

    selectRole = (index) => {
        var {coachRoles} = this.state;
        coachRoles.forEach((r, i) => {
            r.selected = (i === index);
        });
        this.setState({
            coachRoles
        });
    }

    back = () => {
        const {step} = this.state.step;
        if (step === 1) {
            this.props.toggle && this.props.toggle();
        } else {
            this.setState({
                step: (this.state.step - 1)
            });
        }
    }



    ok = () => {
        // TODO: 'persist' selected options and go to the next step
        this.props.fnOk && this.props.fnOk({
            selectedUser: this.state.selectedUser,
            selectedRole: _.find(this.state.coachRoles, (r) => {
                return r.selected;
            }),
            selectedSeason: this.props.season,
            selectedLeague: this.props.league,
            mode: this.state.mode
        });
    }

    addFamilyMember = () => {
        this.props.fnAddFamilyMember && this.props.fnAddFamilyMember();
    }

    render() {

        const {isOpen, toggle, season, family, cart, familyRegistration} = this.props,
            {bootstrapped, step, selectedUser, coachRoles, mode} = this.state;

        var filteredFamilyRegistration = _.filter(familyRegistration, (fr) => {
            return ((fr.IdRole === 4 && mode === 2) || (fr.IdRole === 3 && mode === 1));
        });

        return (
            <Modal isOpen={ isOpen } toggle={ toggle }>
              <ModalBody className="d-flex flex-row p-2">
              <span className="align-self-center me-auto font-16 idented-half">Register on
                            { ' ' }
                            { season.SeasonName || season.Name }</span>
                { step === 2 && <button className="btn btn-link btn-sm green" onClick={ this.addFamilyMember }><i className="fa fa-plus" />{' '}Add Family Member</button>}
                <button onClick={ toggle } type="button" className="btn btn-link align-self-center"><i className="fa fa-times" /></button>
                          
              </ModalBody>
              <ModalBody>
                <div className="bg-info d-flex justify-content-center">
                  { step === 1 && <span className="white">What do you want to register?</span> }
                  { step === 2 && <span className="white">Who's{ ' ' }{ mode === 1 ? 'playing' : 'coaching' }</span> }
                </div>
              </ModalBody>
              <ModalBody id="registration-player-selection" 
              style={ { minHeight: (bootstrapped ? (step === 1 ? 400 : 0) : '300px'), position: 'relative' } }>
                { /* Loader */ }
                { !bootstrapped && (
                  <div className="d-flex justify-content-center centered-message" style={ { height: '300px' } }>
                    <h1 className=" align-self-center block text-center"><i className="fa fa-spin fa-cog" /></h1>
                  </div>) }
                { /* Mode */ }
                { bootstrapped && step === 1 && (
                  <div className="d-flex justify-content-center centered-message" style={ { height: 400 } }>
                    <ul className=" align-self-center text-center d-flex flex-wrap">
                      <li className="tile">
                        <button onClick={ () => this.selectMode(1) } className=" align-self-center justify-content-center d-flex flex-column" style={ { width: '100%', height: '100%' } }>
                          <i className="fa fa-walking align-self-center bigest-font"></i>
                          <span className=" align-self-center margin-top-half">Player</span>
                        </button>
                      </li>
                      <li className="tile d-flex justify-content-center">
                        <button onClick={ () => this.selectMode(2) } className=" align-self-center justify-content-center d-flex flex-column" style={ { width: '100%', height: '100%' } }>
                          <i className="fa fa-bullhorn align-self-center bigest-font"></i>
                          <span className=" align-self-center margin-top-half">Coach</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  ) }
                { /* Family */ }
                { bootstrapped && step === 2 && (
                  <ul className="row p-2">
                    { family.map((user, i) => (
                          <li key={ i } className="selectable-user col-sm" style={{maxWidth: 200, flexBasis: 'auto'}}>
                            <button onClick={ () => this.selectUser(user) }>
                              <SelectableUser mode={mode} user={ user } className="flex-column" familyRegistration={ filteredFamilyRegistration } season={ season } cart={ cart } />
                            </button>
                          </li>)
                      ) }
                  </ul>) }
                { /* Role */ }
                { bootstrapped && step === 3 && selectedUser && (
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center">
                      <SelectableUser user={ selectedUser } className="flex-column" />
                    </div>
                    <ul className="d-flex flex-row justify-content-around">
                      { coachRoles.map((role, i) => (
                            <li key={ i }>
                              <button onClick={ () => this.selectRole(i) }>
                                <SelectableTeamRole role={ role } />
                              </button>
                            </li>)) }
                    </ul>
                  </div>) }
              </ModalBody>
              { step > 1 && <ModalFooter className="d-flex flex-row">
                              { step > 1 && <button className="btn btn-sm btn-link" onClick={ this.back }>Back</button> }
                              { step === 3 && <button className="btn btn-sm btn-success" onClick={ this.ok }>Continue</button> }
                            </ModalFooter> }
            </Modal>)
    }
}

export default RegistrationPlayerSelection;