import React from 'react';
import ReactDOM from 'react-dom';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId, validateEmail } from '../../../helpers';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';
import config from '../../../config';
import request from 'superagent';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';

class FamilyAddress extends React.Component {

    state = {
        obj: {
            country: null,
            state: null,
            CountryCode: _.first(config.country_codes)
        },
        selected: {
            country: null,
            state: null
        }
    }

    componentWillReceiveProps = nextProps => {
        const { information, countries, states } = nextProps, { selected } = this.state;        
        if (information && countries && countries.length && information.IdCountry && !selected.country) {
            this.onCountry(_.find(countries, c => c.IdCountry === information.IdCountry));
        }
        if (information && states && states.length && information.IdState && !selected.state) {
            this.onState(_.find(states, s => s.IdState === information.IdState));
        }
        this.setState({ information });
    }

    onCountry = (c) => {
        const { selected } = this.state;        
        selected.country = c;
        this.props.geo_actions && this.props.geo_actions.clearStates && this.props.geo_actions.clearStates();
        this.props.geo_actions && this.props.geo_actions.fetchStates && this.props.geo_actions.fetchStates(c.IdCountry);
        this.setState({ selected });
    }

    onState = (s) => {
        const { selected } = this.state;        
        selected.state = s;
        this.setState({ selected });
    }

    onSave = (e) => {

        e && e.preventDefault && e.preventDefault();

        const { information, selected, obj } = this.state;
        this.setState({ microTransact: true }, () => {
            request.patch(`${config.apiEndpoint}/api/v4/family/main/info`)
                .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                .send({
                    Phone: information.IdPhoneNumber,
                    IdTimeZone: information.IdTimeZone,
                    Line1: obj.address,
                    City: obj.city,
                    ZipCode: obj.zipcode,
                    IdState: selected.state.IdState
                })
                .then((data) => {
                    if (!data.body.success) {
                        // TODO: there was a problem?            
                    }
                    this.props.family_actions.getFamilyInformation();
                    this.setState({ editing: false, microTransact: false });
                    this.props.toggle && this.props.toggle();
                }, ({ response }) => {
                    // TODO: there was a problem?
                    this.setState({ microTransact: false, error: response.body.Message });
                });
        });
    }

    onCountryCode = (c) => {
        const { obj } = this.state;
        obj.CountryCode = c;
        this.setState({ obj });
    }

    onGender = i => {
        const { obj } = this.state;
        obj.idGender = i;
        this.setState({ obj });
    }

    render() {
        const { colors, obj, microTransac, information, selected } = this.state, { user } = this.props;
        const { countries, states } = this.props;

        // To set US and Canada first on the Countries selection
        const splitCountries = _.partition(countries || [], c => {
            return c.ISOCode === 'US' || c.ISOCode === 'CA' || c.ISOCode === 'UK';
        });

        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.editing_address ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-column w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <div className="w3-content mx-auto d-flex flex-column black pl-4">
                        <div className="w3-container bg-white p-4 ms-4">
                            <div className="w-100 d-flex flex-row px-4">
                                {information && <form className="align-self-center card tile p-3 mt-4 w-100">
                                    <div className="w-100 mt-2">
                                        <label className="font-10">Address</label>
                                        <input className="w-100 p-2 bordered rounded" onChange={({ target }) => {
                                            const { obj } = this.state;
                                            obj.address = target.value;
                                            this.setState({ obj });
                                        }} defaultValue={information.Line1} type="text" name="address" placeholder="Address" />
                                    </div>

                                    <div className="w-100 mt-3">
                                        <label className="font-10">City</label>
                                        <input onChange={({ target }) => {
                                            const { obj } = this.state;
                                            obj.city = target.value;
                                            this.setState({ obj });
                                        }} className="w-100 p-2 bordered rounded" defaultValue={information.City} type="text" name="address" placeholder="City" />
                                    </div>

                                    <div className="w-100 mt-3">
                                        <label className="font-10">Postal</label>
                                        <input onChange={({ target }) => {
                                            const { obj } = this.state;
                                            obj.zipcode = target.value;
                                            this.setState({ obj });
                                        }} className="w-100 p-2 bordered rounded" defaultValue={information.ZipCode} type="text" name="address" placeholder="Postal Code" />
                                    </div>


                                    <div className="w-100 mt-3">
                                        <label className="font-10">Country</label>
                                        <Dropdown isOpen={this.state.isCountryOpen ? true : false} toggle={() => this.setState({ isCountryOpen: !this.state.isCountryOpen })} className="bordered rounded w-100">
                                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent', borderColor: 'transparent' }} className="h-100 w-100 text-left d-flex p-2">
                                                <span className="black">{selected.country ? selected.country.CountryName : 'Country'}</span>
                                                <i className="ms-auto fas fa-caret-down align-self-center black" />
                                            </DropdownToggle>
                                            <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                {splitCountries.length >= 1 && splitCountries[0].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.onCountry(c)}>
                                                    {c.CountryName || c.Name}
                                                </DropdownItem>)}
                                                <DropdownItem divider />
                                                {splitCountries.length > 1 && splitCountries[1].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.onCountry(c)}>
                                                    {c.CountryName || c.Name}
                                                </DropdownItem>)}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    {selected.country !== null && <div className="w-100 mt-3">
                                        <label className="font-10">State</label>
                                        <Dropdown isOpen={this.state.isStateOpen ? true : false} toggle={() => this.setState({ isStateOpen: !this.state.isStateOpen })} className="rounded bordered w-100">
                                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent', borderColor: 'transparent' }} className="h-100 w-100 text-left d-flex p-2">
                                                <span className="black">{selected.state ? selected.state.StateName : 'State'}</span>
                                                <i className="ms-auto fas fa-caret-down align-self-center black" />
                                            </DropdownToggle>
                                            <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                {states && states.length >= 1 && states.map((s, i) => <DropdownItem key={i} onClick={() => this.onState(s)}>
                                                    {s.StateName}
                                                    {' ('}
                                                    {s.StateCode}
                                                    {') '}</DropdownItem>)}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>}

                                </form>}
                            </div>


                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Save' : 'Save'}</button>
                        </div>
                    </div>
                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default FamilyAddress;