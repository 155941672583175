import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import CartPopup from '../family/common/CartPopup';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class RegisterLeague extends React.Component {
    state = {
        menu_toggled: false,
        league: null,
        mode: parseInt(localStorage.getItem('sqwadrc.register.mode') || 1, 10)
    }
    componentWillMount() {
        const { id } = this.props.params;
        if (!id) {
            this.props.router.back();
            return;
        }

        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchLeaguesWithSeasons(localStorage.getItem('sqwadrc.league.search'));
        this.props.scout_actions && this.props.scout_actions.fetchSeasonsAvailableForRegistration(this.props.params.id);

    }
    componentDidMount() {
        this.props.scout_actions && this.props.scout_actions.fetchFamilyRegistrations(this.props.params.id);
    }
    componentWillReceiveProps = nextProps => {
        const { leagues, seasons } = nextProps;
        this.setState({
            leagues,
            league: _.find(leagues || [], l => parseInt(l.IdLeague, 10) === parseInt(this.props.params.id, 10)),
            seasons
        }, () => {
            this.props.scout_actions.setLeague(this.state.league);
        });
    }
    onSeason = (season) => {
        this.setState({ selecting_family: true, season });        
        // Here we should compare the registration the family already has
        // 
    }

    /*
    selectedUser,
                selectedRole,
                selectedSeason: this.props.season,
                selectedLeague: this.props.league,
                mode,
                familyRegistration: this.props.familyRegistration
    */
    setOnPouchAndGo = (user) => {

        this.props.pouch_actions &&
            this.props.pouch_actions.set({
                selectedRole: {}, // ?
                selectedUser: user,
                selectedSeason: this.state.season,
                selectedLeague: this.state.league,
                mode: 1,
                familyRegistration: this.props.registrations
            });
        this.props.router && this.props.router.push('/registration/scout');

    }
    //this.props.cart_actions && this.props.cart_actions.close();
    toggleCart = this.props.cart_actions.close;

    renderCommon = () => {
        const { mode, league, seasons } = this.state;

        return <div className="w3-content px-0 container pt-4">
            {league && <div className={`card p-1 d-flex flex-row justify-content-center`}>
                <img className="align-self-center" src={league.LeagueImage} alt={league.LeagueName} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                <span className="montserrat font-14 black text-center line1 align-self-center ms-2">{league.LeagueName}</span>
            </div>}
            <div className="row mt-2">
                <div className="d-flex flex-row w-100">
                    <Link to="/registration/register" className="btn btn-default align-self-center"><i className="fas fa-arrow-left font-30 black" /></Link>
                    <div className="tile  w-100 w-100 align-self-center" >
                        <form key="search-form">
                            <Input className="w-100 p-3 tile card" defaultValue={this.state.search} placeholder="Search" />
                            <button type="submit" onClick={this.onSearch} className="w3-hide" />
                        </form>
                    </div>
                </div>
            </div>
            {league && <div className="d-flex flex-wrap mt-4">
                {seasons && seasons.map((season, index) => {

                    let dates = _.uniq([moment(season.DateStart).format('MMM Do YYYY').toUpperCase(), moment(season.DateEnd).format('MMM Do YYYY').toUpperCase()]);

                    return <button key={index} onClick={() => this.onSeason(season)} className={`pointer btn btn-link w-50 mt-2 ${index % 2 ? 'ps-1' : 'pe-1'}`}>
                        <div className={`card p-4 d-flex flex-row justify-content-center`} >
                            <img className="align-self-center" src={league.LeagueImage} alt={season.LeagueName} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                            <div className="d-flex flex-column align-self-center ms-4">
                                <span className="montserrat font-12 black ">{season.SeasonType}</span>
                                <span className="montserrat font-14 black">{season.SeasonName}</span>
                                <span className="montserrat font-10 black ">{dates.join(' TO ')}</span>
                            </div>
                        </div>
                    </button>
                }
                )}
            </div>}
        </div>
    }
    render() {
        const { is_cart_open, registrations, cart } = this.props, { season } = this.state;
        return (
            <Layout {...this.props} router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>*/}
                    <Menu {...this.state} {...this.props} />


                    <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInLeft" animationOut="slideOutLeft" isVisible={this.state.selecting_family ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                        <section className="bg-whiteish d-flex flex-column w-100" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70 }}>
                            {this.state.selecting_family && season && <div className="w3-container">

                                <div className="w3-content">
                                    <h1>Who's registering?</h1>
                                    <div className="row p-0 m-0 w-100">
                                        {fakeAuth.getFamily().map((guy, i) => {

                                            let status = 'Available', available = true;
                                            if (_.find(registrations, r => r.IdSeason === season.IdSeason && r.IdUser === guy.IdUser)) { status = 'Already registered'; available = false; }
                                            if (_.find(cart, item => item.selectedUser && item.selectedUser.IdUser === guy.IdUser)) { status = 'Already in Cart'; available = false; }
                                            // tODO: in cart

                                            return <Animated key={i} animationInDelay={25 * i} animationIn="fadeInUp" className="col-6 col-md-6 p-1 w3-container w3-cell">
                                                <div className="w-100 py-2 tile card d-flex flex-row h-100 pointer" onClick={() => { if (available) this.setOnPouchAndGo(guy); }}>
                                                    <div className="d-flex flex-row w-100 py-2">
                                                        <div className="align-self-center cover ms-4" style={{ width: 80, height: 80, borderRadius: 40, background: `url(${guy.UserImage}) no-repeat center center black` }} />
                                                        <div className="d-flex flex-column ms-4 align-self-center montserrat">
                                                            <span className="mt-2 font-16 black line1">{`${guy.NameFirst} ${guy.NameLast}`}</span>
                                                            <span className={`mt-2 font-10 ${available ? 'black' : 'red'} line1 toBold`}>{status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Animated>
                                        })}
                                    </div>
                                </div>
                            </div>}
                        </section>
                        <div onClick={() => this.setState({ selecting_family: false })} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                            <i className="fas fa-arrow-left black font-30 align-self-center" />
                        </div>
                    </Animated>

                    {is_cart_open && <CartPopup {...this.props} toggle={this.toggleCart} fnOk={this.toggleCart} isOpen={this.props.is_cart_open} />}

                </section>

            </Layout>
        )
    }
}

export default RegisterLeague;
