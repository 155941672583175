function leagues(state = [], action) {
    switch (action.type) {
        case 'LEAGUES/SET':
            return action.leagues;        
        case 'LEAGUES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default leagues;
