import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import MenuDesktop from './partials/menu_family_desktop';
import MenuMobile from './partials/menu_family_mobile';
import LineLoader from '../partials/line_loader';
import fakeAuth from '../../fakeAuth';
import AddFamily from './partials/add';


class Family extends React.Component {

    state = {
        menu_toggled: false,
        path: [],
        pickingFollowLevel: false,
        user: null,
        followee: null
    }

    // Lifecycle
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetch_family();
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount() {
        const { router } = this.props, { params } = router, { id } = params;
        if (id) {
            this.props.scout_actions && this.props.scout_actions.fetchUser(id);
        }
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.user) {
            this.setState({
                user: nextProps.user,
                followee: _.find(nextProps.followees || [], f => f.IdUserFollow === nextProps.user.IdUser)
            });
        }
    }
    //


    renderCommon = () => {
        const { } = this.state, { } = this.props;
        return <div className="w3-content pl-4 container pt-4">
            <div className="row p-0 m-0">
                <Animated animationInDelay={0} animationIn="fadeInUp" className="col-6 col-md-3 p-1 w3-container w3-cell">
                    <Link className="w-100 py-2 tile card d-flex flex-row h-100" onClick={() => this.setState({ creating_family: true })}>
                        <div className="d-flex flex-column justify-content-center w-100 py-2">
                            <i className="icon-plus font-80 align-self-center" />
                            <span className="line1 mt-2 font-10 align-self-center black text-center">{`Add Family Member`}</span>
                        </div>
                    </Link>
                </Animated>

                {/* YES */}
                {fakeAuth.getFamily().map((guy, i) => <Animated key={i} animationInDelay={25 * i} animationIn="fadeInUp" className="col-6 col-md-3 p-1 w3-container w3-cell">
                    <Link className="w-100 py-2 tile card d-flex flex-row h-100" to={`/family_member/${guy.IdUser}`}>
                        <div className="d-flex flex-column justify-content-center w-100 py-2">
                            <div className="align-self-center cover" style={{ width: 100, height: 100, borderRadius: 50, background: `url(${guy.UserImage}) no-repeat center center black` }} />
                            <span className="line1 mt-2 font-10 align-self-center black text-center">{`${guy.NameFirst} ${guy.NameLast}`}</span>
                        </div>
                    </Link>
                </Animated>)
                }
            </div>
            <div style={{ height: 200 }} />

        </div >
    }
    render() {

        return (
            <Layout router={this.props.router} who="MY FAMILY" className="bg-blue-dark" title="SQWAD SCOUT | Family" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>
                    <Menu {...this.state} {...this.props} />

                    <AddFamily {...this.state} {...this.props} toggle={() => this.setState({ creating_family: false })} />

                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default Family;
