import React from 'react';

class Text extends React.Component {

    state = {
        answer: null
    }

    handleChange = (e) => {
        const { question } = this.props;
        if ((e.target.value + '').trim()) {
            question.answer = {
                Amount: 0,
                AnswerText: (e.target.value + '').trim()
            };
            this.setState({
                answer: (e.target.value + '').trim()
            });
        } else {
            // Fix on questions
            question.answer = null;
        }
    }

    render() {
        const { question } = this.props,
            { answer } = this.state;
        return <div className="idented margin-right margin-top-half d-flex flex-column">
            <div className="d-flex flex-row">
                <span className="text-left w-100 align-self-center">{question.QuestionText}</span>
                {question.Required && <i className="ms-auto fa fa-asterisk red align-self-center" />}
            </div>

            <input type="text" defaultValue={answer} className="form-control margin-top-half " ref={(i) => this.txtA = i} onChange={this.handleChange} />
            <div style={{ height: 10 }} />
        </div>
    }
}

export default Text;