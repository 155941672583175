import React from 'react';
import Item from './Item'
import './style.css';

class List extends React.Component {
    render() {
        const {paymentPlans, fnSelect} = this.props;
        return (
            <section id='payment-plans' className="mt-2">
              <ul className="white align-self-center block text-center d-flex flex-column justify-content-center w-100 p-0">
              { paymentPlans && paymentPlans.map((pp, i) => (
                <li key={i} className="w-100 m-0 p-0">
                  <button onClick={() => fnSelect && fnSelect(pp)} className="w-100 justify-content-center block h-100 btn btn-link p-0">
                      <Item paymentPlan={ pp } />
                  </button>
                </li>
              ))}
              </ul>
            </section>
            )
    }
}

export default List;