import React from 'react';
import moment from 'moment';

const Season = ({ season = {}, dates }) => {
  return (
    <div className="d-flex flex-row justify-content-start montserrat" style={{ height: 80 }}>
      {/* LOGO */}      
      <div className='ms-2 d-flex contain contains flex-row justify-content-center' style={{
        width: 80, height: 80, background: `url(${season.LeagueImage ||
          season.LeagueImageUrl ||
          season.SeasonImage ||
          `https://api.sportslogic.net/api/v4/leagues/image/decode/${season.IdLeague}`}?full=80) no-repeat center center`
      }} />
      {/* NAME */}
      <div className="d-flex flex-column align-self-center w-75 px-3">
        <span className={`black font-${(season.SeasonName || season.Name || season.LeagueName).length > 30 ? '10' : '14'} line1`}>{(season.SeasonName || season.Name || season.LeagueName)}</span>
        {dates && <span className="text-left font-8 gray mr-2 mt-1 line1">{moment(season.DateStart).format('MMM Do YYYY')} - {moment(season.DateEnd).format('MMM Do YYYY')}</span>}
      </div>
    </div>
  );
}

export default Season;
