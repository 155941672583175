export function set(cart){
    
    return { type: 'CART/SET', cart }
}

export function clear(){
    return { type: 'CART/SET', cart: [] }
}

export function open(){
    return { type: 'IS_CART_OPEN/SET', is_cart_open: true }
}

export function close(){
    return { type: 'IS_CART_OPEN/SET', is_cart_open: false }
}

export function removeItem(id){
    return { type: 'CART/REMOVE/ITEM', id }
}

export function clearLeagueCart(idLeague){
    return { type: 'CART/REMOVE/LEAGUE', idLeague }
}