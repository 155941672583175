import React from 'react';
import _ from 'lodash';
import { formatPrice } from '../../helpers';

class SelectableUser extends React.Component {

    state = {
        user: null,
        inCart: false,
        registered: false
    }

    componentWillMount() {
        const { familyRegistration, season, user, cart, mode } = this.props;
        let inCart = false,
            registered = false;

        if (familyRegistration && season) {
            registered = _.find(familyRegistration, (fr) => {
                return fr.IdSeason === season.IdSeason && fr.IdUser === user.IdUser;
            }) ? true : false;
            user.registration = _.find(familyRegistration, (fr) => {
                return fr.IdSeason === season.IdSeason && fr.IdUser === user.IdUser;
            });
        }
        if (cart && cart.length && mode) {
            inCart = _.find(cart, (item) => {
                return item.selectedUser.IdUser === user.IdUser &&
                    item.selectedSeason.IdSeason === season.IdSeason
                    && !item.selectedTeam && ((!item.selectedRole && mode === 1) || (item.selectedRole && mode === 2))
            }) ? true : false;
        }

        this.setState({
            user,
            inCart,
            registered
        });
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            user: nextProps.user
        });
    }

    noImage = () => {
        const { user } = this.state;
        user.UserImage = user.UserImageUrl = ['/images/defaults/', user.IdGender === 4 ? 'female' : 'male', '.png'];
        this.setState({
            user
        });
    }

    render() {
        const { user, inCart, registered } = this.state;
        return user.IdRoleStatus ? (
            <div style={{ height: '100%' }} className={`w-100 d-flex ${this.props.className || null}`}>
                <i className={`fas fa-circle ${user.RoleStatus} align-self-center font-8 idented margin-right`}></i>
                <div className="align-self-center" style={{ position: 'relative', width: 30, height: 30 }}>
                    { /* Avatar Default */}
                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 30 }} className="d-flex flex-row justify-content-center">
                        <div style={{ width: 30, height: 30, borderRadius: 15, border: '1px solid #e5e5e5', background: `url('/images/defaults/${user.IdGender === 2 ? 'female' : 'male'}.png') no-repeat center center` }} className="align-self-center cover" />
                    </div>
                    { /* Avatar */}
                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 30 }} className="d-flex flex-row justify-content-center">
                        <div style={{ width: 30, height: 30, borderRadius: 15, border: '1px solid #e5e5e5', background: `url(${user.UserImageUrl}) no-repeat center center` }} className="align-self-center cover" />
                    </div>
                </div>
                <section className="idented-half aligned-self-center justify-content-center d-flex flex-column" style={{ lineHeight: 1 }}>
                    <span>{user.UserName}</span>
                    <span className="font-8">{user.RoleStatus}</span>
                </section>
                {user.Amount && <span className={`ms-auto align-self-center margin-right-half ${user.BLOCK}`}>{formatPrice(user.Amount * 100)}</span>}
                <i className={`fa fa-caret-right ${user.Amount ? '' : 'ms-auto'} font-10 margin-right-half align-self-center`} />
            </div>
        ) : (<figure className={`d-flex margin-top-half w-100 ${this.props.className || 'flex-row'} ${(inCart || registered) ? 'p-2 bg-gray card' : 'p-2'}`} style={{ position: 'relative' }}>
            <div className="align-self-center" style={{ position: 'relative', width: 50, height: 50 }}>
                { /* Avatar Default */}
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 50 }} className="d-flex flex-row justify-content-center">
                    <div style={{ width: 50, height: 50, borderRadius: 5, border: '1px solid #e5e5e5', background: `url('/images/defaults/${user.IdGender === 2 ? 'female' : 'male'}.png') no-repeat center center` }} className="align-self-center cover" />
                </div>
                { /* Avatar */}
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 50 }} className="d-flex flex-row justify-content-center">
                    <div style={{ width: 50, height: 50, borderRadius: 5, border: '1px solid #e5e5e5', background: `url(${user.UserImageUrl || user.UserImage}) no-repeat center center` }} className="align-self-center cover" />
                </div>
            </div>
            <span className="align-self-center text-center margin-top-half idented-half">{user.UserName || [user.NameFirst, user.NameLast].join(' ')}</span>
            {inCart === true && <span className="blue font-8 align-self-center text-center"><i className="fa fa-shopping-cart" />{' '}In Cart</span>}
            {registered === true && <span className="blue font-8 align-self-center text-center"><i className="fa fa-check" />{' '}Registered</span>}
            {user.Amount > 0 && <span className={`ms-auto align-self-center margin-right-half ${user.BLOCK}`}>{formatPrice(user.Amount * 100)}</span>}
            <i className={`fa fa-caret-right ${user.Amount ? '' : 'ms-auto'} font-10 margin-right-half align-self-center`} />
        </figure>)
    }
}

export default SelectableUser;