import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import fakeAuth from '../../fakeAuth';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import request from 'superagent';
import config from '../../config';


class Register extends React.Component {
    state = {
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.register.mode') || 1, 10),
        leagues: null,
        keyword: localStorage.getItem('sqwadrc.league.search')
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchLeaguesWithSeasons(localStorage.getItem('sqwadrc.league.search'));
    }
    componentDidMount() {
    }
    componentWillReceiveProps = nextProps => {
        const { leagues } = nextProps;        
        this.setState({ leagues, searching: false });
    }
    onLeague = (league) => {
        this.props.scout_actions.setLeague(league);
    }
    onSearch = (e) => {
        e && e.preventDefault();
        localStorage.setItem('sqwadrc.league.search', this.txtSearch.value);
        this.setState({ searching: true }, () => {
            this.props.scout_actions && this.props.scout_actions.fetchLeaguesWithSeasons(localStorage.getItem('sqwadrc.league.search'));
        });       
    }

    renderCommon = () => {
        const { mode, leagues, dirty, other_leagues } = this.state;

        return <div className="w3-content px-0 container pt-4">
            <div className="row">
                <div className="d-flex flex-row w-100">
                    <div className="tile  w-100 w-100" >
                        <form key="search-form" className="d-flex flex-row">
                            <input ref={i => this.txtSearch = i} defaultValue={this.state.keyword} className="p-3 tile card w-100" placeholder="Search" />
                            <button className="btn btn-link" type="submit" onClick={this.onSearch} ><i className={`fas ${this.state.searching ? 'fa-cog fa-spin' : 'fa-search'}`} /></button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap">
                {(other_leagues || leagues) && (other_leagues || leagues).map((league, index) =>
                    <Link onClick={() => this.onLeague(league)} to={`/registration/register/${league.IdLeague}`} className={`w-50 mt-2 ${index % 2 ? 'ps-1' : 'pe-1'}`}>
                        <div className={`card p-4 d-flex flex-column justify-content-center`} key={index}>
                            <img className="align-self-center" src={league.LeagueImage} alt={league.LeagueName} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                            <span className="montserrat font-14 black text-center line1">{league.LeagueName}</span>
                        </div>
                    </Link>
                )}
            </div>
            {(other_leagues || leagues) && !(other_leagues || leagues).length && !dirty && <div className="w3-container d-flex flex-column justify-content-center mt-4">
                <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" style={{ maxWidth: 100 }} />
                <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4 montserrat">Please use the above search box and enter in the Season or League name that you want to participate in</span>
            </div>}
        </div>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | REGISTER" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>*/}
                    <Menu {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default Register;
