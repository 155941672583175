import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* family_subscriptions() {
    yield* takeEvery("FAMILY/SUBSCRIPTIONS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ }) => {
                return request.get(`${config.apiEndpoint}/api/v4/video/subscriptions/by_family`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'SUBSCRIPTIONS/SET', subscriptions: result.subscriptions });
            } else {
                yield put({
                    type: 'SUBSCRIPTIONS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'SUBSCRIPTIONS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* family_members() {
    yield* takeEvery("MEMBERS/FAMILY/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v4/family/members`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                localStorage.setItem('sqwadrc.family', JSON.stringify(result.users));
                yield put({ type: 'FAMILY/SET', family: result.users });
            } else {
                yield put({
                    type: 'MEMBERS/FAMILY/FETCH_FAILED',
                    result
                });
            }

        } catch (e) {
            yield put({
                type: 'MEMBERS/FAMILY/FETCH_FAILED',
                e
            });
        }
    });
}

export function* all_plans() {
    yield* takeEvery("PLANS/FETCH", function* (action) {
        try {

            const result = yield call(({ }) => {
                return request.get(`${config.apiEndpoint}/api/v4/video/all_plans`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                // all_family_plans, all_scout_plans, all_team_plans
                yield put({ type: 'PLANS/SET', plans: { scout: result.all_scout_plans, family: result.all_family_plans, team: result.all_team_plans } });
            } else {
                yield put({
                    type: 'PLANS/FETCH_FAILED',
                    result
                });
            }

        } catch (e) {
            yield put({
                type: 'PLANS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* update_coach_profile() {
    yield* takeEvery("COACH_PROFILE/UPDATE", function* (action) {
        try {            
            const result = yield call(({
                IdCoachProfile,
                IdUser,
                SchoolName,
                Division,
                RoleName,
                IdState,
                City,
                Conference,
                Type,
                Communities
            }) => {
                return request[IdCoachProfile ? 'patch' : 'post'](`${config.apiEndpoint}/api/v5/family/coach_profile${IdCoachProfile ? `/${IdCoachProfile}` : ''}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .send({
                        IdCoachProfile,
                        IdUser,
                        SchoolName,
                        Division,
                        RoleName,
                        IdState,
                        City,
                        Conference,
                        Type,
                        Communities
                    })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'COACH_PROFILE/FETCH', idUser: action.IdUser });
            } else {
                yield put({
                    type: 'COACH_PROFILE/FETCH_FAILED',
                    result
                });
            }

        } catch (e) {
            yield put({
                type: 'COACH_PROFILE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_coach_profile() {
    yield* takeEvery("COACH_PROFILE/FETCH", function* (action) {
        try {

            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/v5/family/coach_profile/${idUser}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                // all_family_plans, all_scout_plans, all_team_plans
                const { profile, communities } = result;
                if (profile && profile.IdCoachProfile)
                    yield put({ type: 'COACH_PROFILE/SET', profile: { ...profile, ..._.first(communities || [{}]) } });
                else yield put({ type: 'COACH_PROFILE/SET', profile: {} });
            } else {
                yield put({
                    type: 'COACH_PROFILE/FETCH_FAILED',
                    result
                });
            }

        } catch (e) {
            yield put({
                type: 'COACH_PROFILE/FETCH_FAILED',
                e
            });
        }
    });
}