import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { formatPrice } from '../../../../helpers';

class Comp extends React.Component {

    state = {
        answer: null
    }

    handleSelect = (obj) => {
        let answer = obj ? obj.value : 0;
        const { question } = this.props;
        question.answer = _.find(question.Answers, (a) => { return a.IdAnswer === answer });
        this.setState({ answer });
    }

    render() {
        const { question } = this.props,
            { answer } = this.state;

        const options = _.map(question.Answers, (answer) => {
            return {
                label: answer.AnswerText + (answer.Amount !== 0 ? ' (' + formatPrice(answer.Amount * 100) + ')' : ''),
                value: answer.IdAnswer
            }
        });

        return <div className="idented black margin-right margin-top-half d-flex flex-column ">
            <div className="d-flex flex-row">
                <span className="text-left w-100 align-self-center">{question.QuestionText}</span>
                {question.Required && <i className="ms-auto fa fa-asterisk red align-self-center" />}
            </div>
            <Select className="margin-top-half" options={options} value={answer} name="selectAnswer" onChange={this.handleSelect} placeholder="Select..."
            />
            <div style={{ height: 10 }} />
        </div>
    }
}

export default Comp;