import _ from 'lodash';
import Crypto from 'crypto-js';
import moment from 'moment';

const keys = ['NameFirst', 'NameLast', 'UserName', 'UserImage', 'DateBirth', 'Name', 'Image', 'ImageUrl',
    'PhoneNumber_Format', 'PhoneNumber', 'CarrierName', 'EmailAddress', 'Line1', 'City', 'FullPhoneNumber',
    'State', 'Country', 'Address', 'TeamName', 'UserImageUrl', 'FamilyLastName', 'Organizer_EmailAddress',
    'Organizer_Name', 'Confirmation', 'GatewayName', 'DocumentNumber', 'number', 'name', 'cvv', 'expDate', 'expdate', 'zip'];

export const height_units = [
    { title: 'Feets | Inches', value: 1, mask: `_'__''` },
    { title: 'Meters', value: 2, mask: '_.__m' }
];

export const BTEC = [
    { title: 'N/A' },
    { title: 'L3 Diploma', value: 1 },
    { title: 'L3 Extended Certificate', value: 2 },
    { title: 'L3 Extended Diploma', value: 3 }
];

export const EDUCATIONAL_SYSTEMS = [
    { title: 'United States', value: 1, system: 1, image: 'https://api.sportslogic.net/images/flags/US.png' },
    { title: 'England', value: 2, system: 2, image: 'https://api.sportslogic.net/images/flags/GB-UKM.png' },
    { title: 'Northern Ireland', value: 3, system: 2, image: 'https://api.sportslogic.net/images/flags/GB-NIR.png' },
    { title: 'Scotland', value: 4, system: 2, image: 'https://api.sportslogic.net/images/flags/GB-SCT.png' },
    { title: 'Wales', value: 5, system: 2, image: 'https://api.sportslogic.net/images/flags/GB-WLS.png' },
];

export function preload(props, force) {
    //const { medals, medalsActions, upcoming, tournamentActions, alumnis, alumniActions } = props;
    //(!medals || !medals.length || force) && medalsActions && medalsActions.fetchFull();
    //(!upcoming || !upcoming.length || force) && tournamentActions && tournamentActions.fetchUpcoming();
    //(!alumnis || !alumnis.length || force) && alumniActions && alumniActions.fetch();
}

export function humanize_user(user) {
    let offset = parseInt(moment().utc().format('D'));
    keys.forEach(k => {
        // TODO: i'm not sure about the SALT issue
        try { user[k] = Crypto.TripleDES.decrypt(user[k], `secret-${offset}-secret`).toString(Crypto.enc.Utf8); }
        catch (e) { }
    });
}

export function formatPrice(cents) {
    return `$${(cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function formatPriceWithoutCurrency(cents) {
    return `${(cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function rando(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

export function validateEmail(email) {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());

}

export function getFunName() {
    const adjectives = ['adorable', 'beautiful', 'clean', 'drab', 'elegant', 'fancy', 'glamorous', 'handsome', 'long', 'magnificent', 'old-fashioned', 'plain', 'quaint', 'sparkling', 'ugliest', 'unsightly', 'angry', 'bewildered', 'clumsy', 'defeated', 'embarrassed', 'fierce', 'grumpy', 'helpless', 'itchy', 'jealous', 'lazy', 'mysterious', 'nervous', 'obnoxious', 'panicky', 'repulsive', 'scary', 'thoughtless', 'uptight', 'worried'];

    const nouns = ['women', 'men', 'children', 'teeth', 'feet', 'people', 'leaves', 'mice', 'geese', 'halves', 'knives', 'wives', 'lives', 'elves', 'loaves', 'potatoes', 'tomatoes', 'cacti', 'foci', 'fungi', 'nuclei', 'syllabuses', 'analyses', 'diagnoses', 'oases', 'theses', 'crises', 'phenomena', 'criteria', 'data'];

    return `${rando(adjectives)}-${rando(adjectives)}-${rando(nouns)}`;
}

export function calculateBWContrast(hex, bw) {

    if (hex.indexOf('#') === 0) { hex = hex.slice(1); }

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    let o = Math.round(((r * 299) +
        (g * 587) +
        (b * 114)) / 1000);

    return o > 125
        ? '#000000'
        : '#FFFFFF';
}

export function LightenDarkenColor(col, amt) {

    if (col[0] == "#") {
        col = col.slice(1);
    }
    // change #CCC to #CCCCCC
    if (col.length === 3) { col = `${col}${col}` }

    let num = parseInt(col, 16);
    let r = (num >> 16) + amt;
    let b = (num & 0x0000FF) + amt;
    let g = ((num >> 8) & 0x00FF) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    // convert 3-digit hex to 6-digits. (I think this is not working)
    let hex = (g | (b << 8) | (r << 16)).toString(16);
    if (hex.length === 1) {
        hex = hex[0] + hex[0] + hex[0] + hex[0] + hex[0] + hex[0];
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    return `rgb(${r},${g},${b})`;
    //return (usePound ? "#" : "") + hex;
}

export function calculateBWContrastClass(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    let o = Math.round(((r * 299) +
        (g * 587) +
        (b * 114)) / 1000);

    return o > 125
        ? 'black'
        : 'white';
}

export const ItemTypes = {
    DIVISION: 'division',
    TEAM: 'team',
    FLIGHT: 'flight',
    FLIGHTEDTEAMS: 'flightedTeam',
    GAMEDAYRULE: 'gamedayrule',
    EXCEPTIONRULE: 'exceptionrule',
    FIELDAVAILABILITYRULE: 'fieldavailabilityrule',
    BRACKETPLACEMENT: 'bracketplacement',
    ROSTEREDPLAYER: 'rosteredplayer',
    NONROSTEREDPLAYER: 'nonrosteredplayer',
    REGISTRATIONFEE: 'registrationfee',
    TRYOUT: 'tryout',
    ADJUSTMENT: 'adjustment',
    ZIPCODE: 'zipcode',
    FAMILYADJUSTMENT: 'family_adjustment',
    FREETEXT: '2',
    SINGLESELECTION: 'single_selection',
    MULTIPLESELECTION: 'multiple_selection',
    RADIO: '6',
    CHECK: '3',
    CHECKLIST: '4',
    SELECT: '5',
    QANDA: 'q_and_a',
    QUESTIONGROUP: 'question_group',
    ROSTERLOCK: 'roster_lock',
    APPLIEDQUESTION: 'applied_question'
};

export function log(args) {
    _.keys(args).forEach(key => {

    });
}

export function generateRandomId(x) {
    const data = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F'];
    return ("ID-" + _.sampleSize(data, x).join(''));
}

export function detectCreditCardType(number) {
    // visa
    let re = new RegExp("^4");
    if (number.match(re) != null)
        return {
            IdPaymentMethod: 10,
            Name: "Visa",
            Image: "/images/icon/visa-white.png"
        };

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
        return {
            IdPaymentMethod: 20,
            Name: "Mastercard",
            Image: "/images/icon/mastercard-white.png"
        };

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return {
            IdPaymentMethod: 30,
            Name: "Amex",
            Image: "/images/icon/card-white.png"
        };

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return {
            IdPaymentMethod: 40,
            Name: "Discover",
            Image: "/images/icon/card-white.png"
        };

    return {
        Image: "/images/icon/card-white.png"
    };
}

export function toTitleCase(str) {

    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );

}

export function getOrdinal(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function get_masked_phone({ country_code, entry }) {
    if (!entry) return entry;

    let country_codes = [
        { title: 'United States', value: '+1', image: '/images/flags/us.png', mask: '(___) ___-____' },
        { title: 'Canada', value: '+1', image: '/images/flags/ca.png', mask: '(___) ___-____' },
        { title: 'United Kingdom', value: '+44', image: '/images/flags/gb.png', mask: '____ ___ ___' },
        { title: 'Australia', value: '+61', image: '/images/flags/au.png', mask: '(__) ____-____' },
        { title: 'Austria', value: '+43', image: '/images/flags/at.png', mask: '(___) ___-____' },
        { title: 'Bermuda', value: '+1-441', image: '/images/flags/ber.png', mask: '(___) ___-____' },
        { title: 'Denmark', value: '+45', image: '/images/flags/de.png', mask: '(___) ___-____' },
        { title: 'France', value: '+33', image: '/images/flags/fr.png', mask: '__ __ __ __ __' },
        { title: 'Germany', value: '+49', image: '/images/flags/de.png', mask: '(___) ___-____' },
        { title: 'New Zealand', value: '+64', image: '/images/flags/nz.png', mask: '(___) ___-____' },
        { title: 'Norway', value: '+47', image: '/images/flags/no.png', mask: '(___) ___-____' },
        { title: 'Panama', value: '+507', image: '/images/flags/pa.png', mask: '____-____' },
        { title: 'Scotland', value: '+44', image: '/images/flags/sct.png', mask: '(___) ___-____' },
        { title: 'Sweden', value: '+46', image: '/images/flags/sw.png', mask: '(___) ___-____' },
        { title: 'Switzerland', value: '+41', image: '/images/flags/sw.png', mask: '(___) ___-____' },
        { title: 'Wales', value: '+44', image: '/images/flags/wa.png', mask: '(___) ___-____' },
    ];

    let country = _.find(country_codes, c => c.value === country_code) || _.first(country_codes);
    let i = 0;
    try {
        return country.mask.replace(/_/g, l => (entry[i++] || ' '));
    } catch (e) {
        return entry;
    }
}

export function fix_phone_numbers(u) {
    u.PhoneNumber = get_masked_phone({ country_code: u.CountryCode, entry: u.IdPhoneNumber });
    u.PhoneNumber_Format = `${u.CountryCode || '+1'} ${u.PhoneNumber}`;
}

export function default_player_match(me) {
    return {
        CreatorNameFirst: me.NameFirst,
        CreatorNameLast: me.NameLast,
        EvaluationDate: moment(),
        Date: moment(),
        IdFolder: null, // TODO
        IdGender: 1,
        ExternalLinks: [],
        Home: {
            Roster: [{
                Index: 0
            }, {
                Index: 1
            }, {
                Index: 2
            }, {
                Index: 3
            }, {
                Index: 4
            }, {
                Index: 5
            }, {
                Index: 6
            }, {
                Index: 7
            }, {
                Index: 8
            }, {
                Index: 9
            }, {
                Index: 10
            }, {
                Index: 11
            }, {
                Index: 12
            }, {
                Index: 13
            }, {
                Index: 14
            }, {
                Index: 15
            }, {
                Index: 16
            }, {
                Index: 17
            }, {
                Index: 18
            }, {
                Index: 19
            }, {
                Index: 20
            }, {
                Index: 21
            }, {
                Index: 22
            }, {
                Index: 23
            }],
            Formation: 0,
            Manager: '',
            Name: '',
            Score: 0,
        },
        Away: {
            Roster: [{
                Index: 0
            }, {
                Index: 1
            }, {
                Index: 2
            }, {
                Index: 3
            }, {
                Index: 4
            }, {
                Index: 5
            }, {
                Index: 6
            }, {
                Index: 7
            }, {
                Index: 8
            }, {
                Index: 9
            }, {
                Index: 10
            }, {
                Index: 11
            }, {
                Index: 12
            }, {
                Index: 13
            }, {
                Index: 14
            }, {
                Index: 15
            }, {
                Index: 16
            }, {
                Index: 17
            }, {
                Index: 18
            }, {
                Index: 19
            }, {
                Index: 20
            }, {
                Index: 21
            }, {
                Index: 22
            }, {
                Index: 23
            }],
            Formation: 0,
            Manager: '',
            Name: '',
            Score: 0,
        },
        IdPlayerEvaluation: generateRandomId(10),
        IdUser: me.IdUser,
        NameFirst: '',
        NameLast: '',
        ReportType: 2,
        IdUserTarget: null,
        IdUserFollow: null,
    }
}

export function default_team_match(me) {
    return {
        CreatorNameFirst: me.NameFirst,
        CreatorNameLast: me.NameLast,
        EvaluationDate: moment(),
        Date: moment(),
        IdFolder: null, // TODO
        IdGender: 1,
        ExternalLinks: [],
        Home: {
            Focus: true,
            Roster: [{
                Index: 0
            }, {
                Index: 1
            }, {
                Index: 2
            }, {
                Index: 3
            }, {
                Index: 4
            }, {
                Index: 5
            }, {
                Index: 6
            }, {
                Index: 7
            }, {
                Index: 8
            }, {
                Index: 9
            }, {
                Index: 10
            }, {
                Index: 11
            }, {
                Index: 12
            }, {
                Index: 13
            }, {
                Index: 14
            }, {
                Index: 15
            }, {
                Index: 16
            }, {
                Index: 17
            }, {
                Index: 18
            }, {
                Index: 19
            }, {
                Index: 20
            }, {
                Index: 21
            }, {
                Index: 22
            }, {
                Index: 23
            }],
            Formation: 0,
            Manager: '',
            Name: '',
            Score: 0,
        },
        Away: {
            Roster: [{
                Index: 0
            }, {
                Index: 1
            }, {
                Index: 2
            }, {
                Index: 3
            }, {
                Index: 4
            }, {
                Index: 5
            }, {
                Index: 6
            }, {
                Index: 7
            }, {
                Index: 8
            }, {
                Index: 9
            }, {
                Index: 10
            }, {
                Index: 11
            }, {
                Index: 12
            }, {
                Index: 13
            }, {
                Index: 14
            }, {
                Index: 15
            }, {
                Index: 16
            }, {
                Index: 17
            }, {
                Index: 18
            }, {
                Index: 19
            }, {
                Index: 20
            }, {
                Index: 21
            }, {
                Index: 22
            }, {
                Index: 23
            }],
            Formation: 0,
            Manager: '',
            Name: '',
            Score: 0,
        },
        IdPlayerEvaluation: generateRandomId(10),
        IdUser: me.IdUser,
        NameFirst: '',
        NameLast: '',
        ReportType: 3,
        IdUserTarget: null,
        IdUserFollow: null,
    }
}

export function getMaskedValue(v, mask) {
    let i = 0;
    return mask.replace(/_/g, l => (v.toString()[i++] || '_'));
}

export function encrypt_user(user) {
    let offset = parseInt(moment().utc().format('D'));
    keys.forEach(k => {
        // TODO: i'm not sure about the SALT issue
        try { if (user[k]) user[k] = Crypto.TripleDES.encrypt(user[k], `secret-${offset}-secret`).toString(); }
        catch (e) { }
    });
}


export function mask_phone_number({ entry,
    mask = /([^\d]*\d[^\d]*){0,10}$/,
    reverse = '(__) ___-____' }) {

    const match = entry
        .replace(/\D+/g, '') // removes any non-number (+...)
        //.replace(/^1/, '') // removes the start 1
        .match(mask)[0]; // process regex
    let i = 0;
    return reverse.replace(/_/g, l => (match[i++] || '_'));
}

export function isDST() {
    // Get the current date
    const currentDate = new Date();

    // Get the standard time offset for January (assuming no DST in January)
    const january = new Date(currentDate.getFullYear(), 0, 1);
    const standardOffset = january.getTimezoneOffset();

    // Get the current time offset
    const currentOffset = currentDate.getTimezoneOffset();

    // If the current time offset is not equal to the standard offset, DST is in effect
    return currentOffset !== standardOffset;
}
