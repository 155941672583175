function reducer(state = [], action) {
    switch (action.type) {
        case 'IS_CART_OPEN/SET':
            return action.is_cart_open;
        default:
            return state;
    }
}

export default reducer;
