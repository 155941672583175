import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import MenuNotes from '../network/partials/menu_notes';
import BlackOverlay from '../partials/black_overlay';
import _ from 'lodash';
import fakeAuth from '../../fakeAuth';
import request from 'superagent';
import config from '../../config';
import { formatPrice } from '../../helpers';

let SignaturePad = require('react-signature-pad');

class Subscriptions extends React.Component {
    state = {
        menu_toggled: false,
        step: 1,
        coupon_code: null,
        card: {
            number: null,
            name: null,
            expDate: null,
            cvv: null,
            zip: null,
            cash: false,
            check: false,
        },
        total: 0,
        success: false
    }

    // Lifecycle
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetch_family_subscriptions();
        this.props.scout_actions && this.props.scout_actions.fetch_all_plans();
        this.props.geo_actions && this.props.geo_actions.fetch_disclaimer(1);
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ subscriptions: nextProps.subscriptions }, () => {
            this.setState({
                plans: (nextProps.plans || {}).scout,
                subscription: _.find(nextProps.subscriptions, s => s.IdPlan === '7404C09F-9E11-4FBF-BF82-B2C487A911F5' && !s.Expired && s.IdUser === fakeAuth.getUser().IdUser),
                bootrstrapped: true,
            });
        });
    }
    //

    onTerms = () => this.setState({ termsModal: !this.state.termsModal });
    onApply = () => {
        if (this.txtCoupon.value || this.state.coupon_code) {
            this.setState({ error: null }, () => {
                request.post(`${config.apiEndpoint}/api/v4/coupons/apply_membership`)
                    .send({ CouponName: this.txtCoupon.value || this.state.coupon_code })
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(({ body }) => {
                        let { coupon } = body, { total } = this.state;
                        if (coupon) {
                            if (coupon.PercentPerCoupon) {
                                total = total - (total * coupon.PercentPerCoupon / 100);
                            } else if (coupon.AmountPerCoupon) {
                                total = total - coupon.AmountPerCoupon;
                                if (total < 0) total = 0;
                            }
                            this.setState({ total, coupon });
                        }

                    }, reject => {
                        this.setState({ error: 'Invalid Coupon' });
                    });
            });
        }
    }
    onPay = () => {        
        if (this.state.microTransac) return;
        if (this.state.card.number &&
            this.state.card.name &&
            this.state.card.cvv &&
            this.state.card.expDate &&
            this.state.card.zip) {
            this.setState({ microTransac: true, error: null }, () => {                
                request.post(`${config.apiEndpoint}/api/v6/subscriptions/subscribe_scout`)
                    .send({
                        IdPlan: this.state.plan.IdPlan,
                        card: this.state.card,
                        users: [fakeAuth.getUser().IdUser],
                        discounts: _.compact([this.state.coupon])
                    })
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .then(({ body }) => {                        
                        this.setState({ microTransac: false, success: true, IdExternal: body.IdExternal });
                    }, ({ response: { body } }) => {
                        this.setState({ microTransac: false, success: true, error: body.error.message || 'Could not complete transaction. Please review your payment information.' });
                    });
            });
        } else this.setState({ error: 'Please complete your payment information' });
    }
    onEnroll = () => {
        if (this.state.microTransac) return;

        this.setState({ microTransac: true, error: null }, () => {
            
            request.post(`${config.apiEndpoint}/api/v6/subscriptions/subscribe_scout`)
                .send({
                    IdPlan: this.state.plan.IdPlan,
                    card: { cash: true },
                    users: [fakeAuth.getUser().IdUser],
                    discounts: _.compact([this.state.coupon])
                })
                .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                .then(({ body }) => {                    
                    this.setState({ microTransac: false, success: true, IdExternal: body.IdExternal });
                }, ({ response: { body } }) => {
                    this.setState({ microTransac: false, success: true, error: body.error.message || 'Could not complete transaction. Please review your payment information.' });
                });
        });
    }


    signatureEnd = () => {
        var signature = this.refs.mySignature;
        this.setState({
            signed: true,
            signatureUrl: signature.toDataURL()
        });
    }

    clearSignature = () => {
        var signature = this.refs.mySignature;
        signature.clear();
        this.setState({
            signed: false,
            signatureUrl: null
        });
    }

    renderCommon = () => {
        const { subscription, bootrstrapped, plans, step, coupon_code, card, error, microTransac, success } = this.state;
        return <div className="w3-content pl-4 container pt-4">

            {!success && <div className="row p-0">
                {plans && plans.map((plan, i) => (
                    <Animated key={i} animationInDelay={25} animationIn="fadeInRight" style={{ maxWidth: 400 }} className="my-4 p-0 rounded align-self-center pointer card tile">
                        <div className="d-flex flex-column p-4">
                            <div className="d-flex flex-row">
                                <i className="icon-star blue font-60"></i>
                                <div className="d-flex flex-column ms-3">
                                    <span className="font-40 line1">Scout</span>
                                    <span className="sqwad-font font-20 line1">PRO</span>
                                </div>
                            </div>
                            <span className="mt-2 sqwad-font toUpper">Valuable Addons</span>
                            <span>- Create and track player evaluations</span>
                            <span>- Scout players and teams during a match</span>
                            <span>- Collaborate and Share reports among staff</span>
                            <span>- Share data quick and easy</span>
                            <span>- Manage and track all aspects of the scouting process.</span>
                        </div>
                        <div className="w3-gray p-3 d-flex flex-column">
                            <span className="align-self-center sqwad-font toUpper line1">{plan.Name}</span>
                            <span className="align-self-center d-flex flex-row">
                                <span className="font-20 line1">${plan.Amount}</span>
                                <span className="align-self-center ms-2 line1">per month</span>
                            </span>
                            <span className="align-self-center font-8 line1">Auto-renrewd every {plan.Duration} days</span>

                            {step === 1 && <button className="btn btn-success mt-3" onClick={() => this.setState({ termsModal: true, step: 1, plan, total: plan.Amount })}>Purchase Now</button>}
                        </div>
                        {error && <div className="bg-danger p-2 white text-center">{error}</div>}
                        {step === 2 && <div className="d-flex flex-column p-4">

                            <div className="w-100 mt-2 d-flex flex-row">
                                <input onChange={({ target }) => {
                                    this.setState({ coupon_code: target.value });
                                }} className="w-100 p-2" type="text" placeholder="Coupon Code" ref={(input) => this.txtCoupon = input} />
                                <button className="btn btn-link" onClick={this.onApply}>Apply</button>
                            </div>
                            {this.state.total > 0 && <section>
                                <div className="w-100 mt-2">
                                    <input onChange={({ target }) => {
                                        const { card } = this.state;
                                        card.number = target.value;
                                        this.setState({ card });
                                    }} className="w-100 p-2" maxLength={16} defaultValue={card.number} type="text" name="card_number" placeholder="Card Number" ref={(input) => this.txtCardNumber = input} />
                                </div>
                                <div className="w-100 mt-2">
                                    <input onChange={({ target }) => {
                                        const { card } = this.state;
                                        card.name = target.value;
                                        this.setState({ card });
                                    }} className="w-100 p-2" defaultValue={card.name} type="text" name="card_name" placeholder="Card Name" ref={(input) => this.txtCardName = input} />
                                </div>
                                <div className="w-100 mt-2 d-flex flex-row">
                                    <input onChange={({ target }) => {
                                        const { card } = this.state;
                                        card.expDate = target.value;
                                        this.setState({ card });
                                    }} className="w-100 p-2" maxLength={4} defaultValue={card.expDate} type="text" name="expDate" placeholder="Exp. Date (MMYY)" ref={(input) => this.txtCardExpDate = input} />
                                    <div className="p-1" />
                                    <input onChange={({ target }) => {
                                        const { card } = this.state;
                                        card.cvv = target.value;
                                        this.setState({ card });
                                    }} className="w-100 p-2" maxLength={4} defaultValue={card.cvv} type="text" name="card_cvv" placeholder="CVV" ref={(input) => this.txtCardCVV = input} />
                                </div>
                                <div className="w-100 mt-2 d-flex flex-row">
                                    <input onChange={({ target }) => {
                                        const { card } = this.state;
                                        card.zip = target.value;
                                        this.setState({ card });
                                    }} className="w-100 p-2" defaultValue={card.zip} type="text" name="zip_code" placeholder="Zip/Postal" ref={(input) => this.txtZip = input} />
                                    <div className="p-1" />
                                    <div className="w-100 p-2" />
                                </div>
                            </section>}
                            {this.state.total > 0 && <button className="btn btn-success mt-3" onClick={this.onPay}>
                                {!microTransac && <span>Pay {formatPrice((this.state.total || 0) * 100)}</span>}
                                {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                            </button>}
                            {this.state.total === 0 && <button className="btn btn-success mt-3" onClick={this.onEnroll}>Pay {formatPrice((this.state.total || 0) * 100)}</button>}
                            {this.state.coupon && <span className="mx-auto">-{formatPrice((this.state.plan.Amount - this.state.total) * 100)} for {this.state.plan.Duration} days</span>}
                        </div>}
                    </Animated>))}
            </div>}

            {success && <div className="row">
                <Animated animationInDelay={25} animationIn="fadeInRight" style={{ width: 400 }} className="my-4 p-4 bg-dark-blue rounded align-self-center pointer card tile">
                    <div className="d-flex flex-column">
                        <div style={{ height: 100 }} />
                        <i className="fas fa-check white font-80 align-self-center" />
                        <span className="white align-self-center font-40">Success!</span>
                        <span className="font-10 align-self-center white">Confirmation number: {this.state.IdExternal}</span>
                        <button className="btn btn-link white mt-4" onClick={() => { this.props.router.goBack && this.props.router.goBack() }} >Click to Continue</button>
                        <div style={{ height: 100 }} />
                    </div>
                </Animated>
            </div>}

        </div>
    }
    render() {
        const { step } = this.state;
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | SCOUTING" form={this.props.form} form_actions={this.props.form_actions}>
                <section className='cover' style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, overflowY: 'auto', background: `url(images/backgrounds/finally.jpg) no-repeat center center`, }}>
                    <div className="h-100 w-100 d-flex justify-content-center" style={{ position: 'relative', }}>

                        <BlackOverlay />

                        {/* DESKTOP */}
                        <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                            <div style={{ height: 65 }} />
                            {this.renderCommon()}
                        </div>
                        {/* MOBILE */}
                        <div className="w3-container w3-hide-medium w3-hide-large">
                            <div style={{ height: 65 }} />
                            {this.renderCommon()}
                        </div>
                        <Menu {...this.state} {...this.props} />
                        <MenuNotes {...this.state} toggle={() => this.setState({ menu_toggled: !this.state.menu_toggled })} />

                    </div>

                    { /* Modal for Terms and Conditions */}
                    <Modal isOpen={this.state.termsModal}>
                        {step === 1 && <ModalHeader className="p-0 m-0">
                            <div className="d-flex flex-row w-100 px-2 m-0">
                                <span className="align-self-center sqwad-font font-14">TERMS AND CONDITIONS</span>
                                <div onClick={() => this.setState({ termsModal: false })} style={{ width: 50, height: 50, borderRadius: 25, }} className="ms-auto bg-gray d-flex flex-column justify-content-center">
                                    <i className="fas fa-times black font-30 align-self-center" />
                                </div>
                            </div>
                        </ModalHeader>}
                        {step === 1 && <ModalBody style={{ overflowY: 'auto', height: 300 }}>
                            <div className="">
                                {this.props.disclaimer && <div id="terms-and-conditions" className="black p-2 m-auto font-8" dangerouslySetInnerHTML={{ __html: this.props.disclaimer ? (this.props.disclaimer.sportslogic || this.props.disclaimer.sportslogic) : null }}></div>}
                            </div>
                        </ModalBody>}
                        {step === 1 && <ModalBody className="bordered">
                            <h3 className="toUpper sqwad-font font-14 px-2">Sign Here please</h3>
                            <SignaturePad onEnd={this.signatureEnd} ref="mySignature" className='w-100' />
                        </ModalBody>}
                        {step === 1 && <ModalFooter>
                            <button className="btn btn-link" onClick={this.clearSignature}>Clear Signature</button>
                            {' '}
                            {this.state.signed && <button className="btn btn-success" onClick={() => this.setState({ step: 2, termsModal: false })}>Continue</button>}
                        </ModalFooter>}
                    </Modal>
                </section>
            </Layout>
        )
    }
}

export default Subscriptions;
