import React from 'react';
import { formatPrice } from '../../../../helpers';

class Item extends React.Component {
  render() {
    const { paymentPlan } = this.props;
    return <figure className="d-flex flex-column mx-0 mt-0 card shadow-box w-100 mb-4">
      <div className={`w-100 d-flex flex-column h-100 ${paymentPlan.selected ? 'bg-success white' : 'black'}`}>
        <div className="d-flex flex-row align-self-center w-100 px-4 pt-2">
          <span className="align-self-center font-12">{paymentPlan.Name}</span>
          
          <i className={`icon-check-circle white align-self-center ms-auto`} />
        </div>
        <ul className="align-self-center w-100 d-flex flex-column padding-top-half pb-4 px-4">
          {paymentPlan.Dues.map((due, i) => (
            <li key={i} className="d-flex flex-row ">
              <span className="font-8 w-100 text-left">{due.Name}</span>
              <span className="font-8 flex-shrink-1">{formatPrice(due.Total * 100)}</span></li>
          ))}
          <li>
            <div style={{ height: 1 }} className="bg-gray mt-2 mb-2" />
          </li>
          <li className="d-flex flex-row ">
            <span className="font-12 w-100 text-left">Total</span>
            <span className="font-12 flex-shrink-1">{formatPrice(paymentPlan.Total * 100)}</span></li>
        </ul>
      </div>
    </figure>
  }
}

export default Item;