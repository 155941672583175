import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* sendLeadSaga() {
    yield* takeEvery("LEADS/ADD", function* (action) {
        try {
            const result = yield call(({ lead }) => {
                return request.post(`${config.apiEndpoint}/api/v5/leads`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .send(lead)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);            
        } catch (e) {
            yield put({ type: 'LEADS/FETCH_FAILED', e });
        }
    });
}