import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import MenuDesktop from './partials/menu_family_desktop';
import MenuMobile from './partials/menu_family_mobile';
import { Link } from 'react-router';
import LineLoader from '../partials/line_loader';
import Email from './email';
import Phone from './phone';
import fakeAuth from '../../fakeAuth';
import config from '../../config';
import request from 'superagent';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generateRandomId, validateEmail } from '../../helpers';
import { isMobile } from 'react-device-detect';
import FamilyAddress from './partials/address';


class FamilyContact extends React.Component {
    state = {
        selected: {
            country: null,
            state: null,
        },
        editing: null,
        validations: {
            txtEmail: false,
            txtEmailConfirmation: false,
            txtPassword: false,
            txtPasswordConfirm: false,
            txtName: false,
            txtLastName: false,
            ddlistGender: false,
            ddlistCountry: false,
            ddlistState: false,
            txtDob: false,
            txtAddress: false,
            txtCity: false,
            txtZip: false,
            txtPhone: false
        },
        obj: {},
        path: [],
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.contact.mode') || 1, 10),
    }
    componentWillMount() {
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetch_family();        
    }
    componentWillReceiveProps = ({ information, countries, states }) => {
        this.setState({ bootstrapped: true, information }, () => {
            const { information, selected } = this.state;
            if (information && countries && countries.length && information.IdCountry && !selected.country) {
                this.onCountry(_.find(countries, c => c.IdCountry === information.IdCountry));
            }
            if (information && states && states.length && information.IdState && !selected.state) {
                this.onState(_.find(states, s => s.IdState === information.IdState));
            }
        });
    }
    componentDidMount() {
        this.props.family_actions && this.props.family_actions.getFamilyInformation();
    }
    componentWillUnmount() {
        this.props.family_actions && this.props.family_actions.clearInfo();
        }


    onCountry = (c) => {
        const { selected } = this.state;
        selected.country = c;
        this.props.geo_actions && this.props.geo_actions.clearStates && this.props.geo_actions.clearStates();
        this.props.geo_actions && this.props.geo_actions.fetchStates && this.props.geo_actions.fetchStates(c.IdCountry);
        this.setState({ selected });
    }

    onState = (s) => {
        const { selected } = this.state;
        selected.state = s;
        this.setState({ selected });
    }

    saveEmail = e => {
        e.preventDefault && e.preventDefault();
        const { obj } = this.state;

        // Validations
        if (!obj.new_email) {
            this.setState({ microTransact: false, error: 'Please provide your new Email' });
            return;
        }
        if (!validateEmail(obj.new_email)) {
            this.setState({ microTransact: false, error: 'Invalid new Email' });
            return;
        }
        if (!obj.confirm_email) {
            this.setState({ microTransact: false, error: 'Please confirm your new Email' });
            return;
        }
        if (!validateEmail(obj.confirm_email)) {
            this.setState({ microTransact: false, error: 'Invalid Email confirmation' });
            return;
        }
        if (obj.new_email !== obj.confirm_email) {
            this.setState({ microTransact: false, error: 'Your New Email and Confirmation must match' });
            return;
        }
        if (!obj.password) {
            this.setState({ microTransact: false, error: 'Please provide your current password' });
            return;
        }
        //

        const { information } = this.state;
        // TODO: call API
        this.setState({ microTransact: true, error: null }, () => {
            request.post(`${config.apiEndpoint}/api/v4/family/main/email`)
                .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                .send({
                    IdEmailAddress: information.IdEmailAddress,
                    EmailAddress: obj.new_email,
                    IsRelated: true
                })
                .then((data) => {
                    if (data.body.success) {
                        this.props.family_actions.getFamilyInformation();
                        this.setState({ microTransact: false, editing: false, obj: {} });
                    }
                }, ({ response }) => {
                    this.setState({ microTransact: false, error: response.body.error.message });
                });
        });

    }
    savePassword = e => {
        e.preventDefault && e.preventDefault();
        const { obj } = this.state;

        // Validations
        if (!obj.password) {
            this.setState({ microTransact: false, error: 'Please provide your Current Password' });
            return;
        }
        if (!obj.new_password) {
            this.setState({ microTransact: false, error: 'Please provide your new Password' });
            return;
        }
        if (!obj.confirm_password) {
            this.setState({ microTransact: false, error: 'Please confirm your new Password' });
            return;
        }
        if (obj.confirm_password !== obj.new_password) {
            this.setState({ microTransact: false, error: 'Your New Password and Confirmation must match' });
            return;
        }
        //

        // TODO: call API
        this.setState({ microTransact: true, error: null }, () => { });
        request.post(`${config.apiEndpoint}/api/v4/family/main/password`)
            .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
            .send({
                Password: obj.password,
                PasswordNew: obj.new_password,
                PasswordNewConfirm: obj.confirm_password
            })
            .then((data) => {
                this.setState({ editing: false, microTransact: false, obj: {} });
            }, ({ response }) => {
                this.setState({ microTransact: false, error: response.body.Message });
            });
    }
    saveAddress = e => {
        e.preventDefault && e.preventDefault();
        const { information, selected, obj } = this.state;
        this.setState({ microTransact: true }, () => {
            request.patch(`${config.apiEndpoint}/api/v4/family/main/info`)
                .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                .send({
                    Phone: information.IdPhoneNumber,
                    IdTimeZone: information.IdTimeZone,
                    Line1: obj.address,
                    City: obj.city,
                    ZipCode: obj.zipcode,
                    IdState: selected.state.IdState
                })
                .then((data) => {
                    if (!data.body.success) {
                        // TODO: there was a problem?            
                    }
                    this.props.family_actions.getFamilyInformation();
                    this.setState({ editing: false, microTransact: false });
                }, ({ response }) => {
                    // TODO: there was a problem?
                    this.setState({ microTransact: false, error: response.body.Message });
                });
        });
    }

    validate = (key) => {
        const { validations, selected } = this.state;
        var valid = true;
        switch (key) {
            case 'ddlistCountry':
                validations.ddlistCountry = selected.country ? true : false;
                valid = !validations.ddlistCountry ? false : valid;
                break;
            case 'ddlistState':
                validations.ddlistState = selected.state ? true : false;
                valid = !validations.ddlistState ? false : valid;
                break;
            case 'txtAddress':
                validations.txtAddress = (this[key].value && this[key].value.length > 0) ? true : false;
                valid = !validations.txtAddress ? false : valid;
                break;
            case 'txtCity':
                validations.txtCity = (this[key].value && this[key].value.length > 0) ? true : false;
                valid = !validations.txtCity ? false : valid;
                break;
            case 'txtZip':
                validations.txtZip = (this[key].value && this[key].value.length > 0) ? true : false;
                valid = !validations.txtZip ? false : valid;
                break;
            case 'txtPhone':
                validations.txtPhone = (ReactDOM.findDOMNode(this[key]).value) ? true : false;
                valid = !validations.txtPhone ? false : valid;
                break;
            default:
                break;
        }
        this.setState({
            validations
        });

        return valid;
    }



    renderCommon = () => {
        const { mode, bootstrapped, information, editing, microTransac, selected, obj } = this.state, { phones, router, emails, family } = this.props, { params } = router;

        const { countries, states } = this.props;

        // To set US and Canada first on the Countries selection
        const splitCountries = _.partition(countries || [], c => {
            return c.ISOCode === 'US' || c.ISOCode === 'CA' || c.ISOCode === 'UK';
        });

        return <div className="w3-content px-0 container pt-4">
            {this.state.error && <div className="p-3 d-flex flex-row font-10 white bg-danger" key="leftActions">
                <i className="align-self-center fas fa-exclamation-triangle margin-right-half text-warning font-20" />
                <span className="align-self-center">{this.state.error}</span>
            </div>}

            {bootstrapped && information && <div className="p-0">

                {!editing && <Animated animationIn="fadeInRight" className="margin-bottom d-flex flex-column justify-content-center w-100 m-auto p-4" style={{ maxWidth: 600 }}>
                    <h3 className="sqwad-font">MAIN ACCOUNT INFORMATION</h3>
                    {/* Email  */}
                    <div className="p-3 tile card d-flex flex-row">
                        <div className="d-flex flex-column align-self-center">
                            <span className="font-8 gray">Email</span>
                            <span className="font-12 dark-blue">{information.EmailAddress}</span>
                        </div>
                        <span className="ms-auto align-self-center font-12 blue underlined pointer hoverable" onClick={() => this.setState({ editing: 1 })}>Edit</span>
                    </div>

                    {/* Password */}
                    <div className="p-3 mt-3 tile card d-flex flex-row">
                        <div className="d-flex flex-column align-self-center">
                            <span className="font-8 gray">Password</span>
                            <span className="font-12 dark-blue">**********</span>
                        </div>
                        <span className="ms-auto align-self-center font-12 blue underlined pointer hoverable" onClick={() => this.setState({ editing: 2 })}>Edit</span>
                    </div>

                    {/* Address */}
                    <div className="p-3 mt-3 tile card d-flex flex-row">
                        <div className="d-flex flex-column align-self-center">
                            <span className="font-8 gray">Address</span>
                            <span className="font-12 dark-blue line1">{information.Line1}<br />{information.City}, {information.State} {information.ZipCode}<br />{information.CountryName || information.Country}</span>
                        </div>
                        <span className="ms-auto align-self-center font-12 blue underlined pointer hoverable" onClick={() => this.setState({ editing_address: true })}>Edit</span>
                    </div>

                </Animated>}

                {editing === 1 && (
                    <Animated animationIn="fadeInRight" style={{ maxWidth: 600 }} className="container m-auto d-flex flex-column justify-content-center w-100">
                        <h3 className="sqwad-font">FAMILY EMAIL</h3>
                        <form className="align-self-center card tile p-3 mt-4 w-100">
                            <div className="w-100 mt-3" data-validate="Type user name">
                                <label className="font-10">New Email</label>
                                <input onChange={({ target }) => {
                                    const { obj } = this.state;
                                    obj.new_email = target.value;
                                    this.setState({ obj });
                                }} autoComplete='false' className="w3-input bordered rounded" type="text" placeholder="New Email" />
                            </div>

                            <div className="w-100 mt-3" data-validate="Type user name">
                                <label className="font-10">Confirm New Email</label>
                                <input onChange={({ target }) => {
                                    const { obj } = this.state;
                                    obj.confirm_email = target.value;
                                    this.setState({ obj });
                                }} autoComplete='false' className="w3-input bordered rounded" type="text" placeholder="New Email" />
                            </div>

                            <div className="w-100 mt-3" data-validate="Type user name">
                                <label className="font-10">Current Password</label>
                                <input onChange={({ target }) => {
                                    const { obj } = this.state;
                                    obj.password = target.value;
                                    this.setState({ obj });
                                }} autoComplete='false' className="w3-input bordered rounded" type="password" />
                            </div>

                            <div className="d-flex flex-row mt-3">
                                <button type="button" className="btn btn-link btn-sm ms-auto" onClick={() => this.setState({ editing: null })}>Cancel</button>
                                {!microTransac && <button type="submit" className="btn btn-success btn-sm" onClick={this.saveEmail}>Save</button>}
                                {microTransac && <button type="button" className="btn btn-success btn-sm">Saving...</button>}
                            </div>
                        </form>
                    </Animated>)}


                {editing === 2 && (
                    <Animated animationIn="fadeInRight" style={{ maxWidth: 600 }} className="container m-auto d-flex flex-column justify-content-center w-100">
                        <h3 className="sqwad-font">FAMILY PASSWORD</h3>
                        <form className="align-self-center card tile p-3 mt-4 w-100">
                            <div className="w-100 mt-3" data-validate="Type user name">
                                <label className="font-10">New Password</label>
                                <input onChange={({ target }) => {
                                    const { obj } = this.state;
                                    obj.new_password = target.value;
                                    this.setState({ obj });
                                }} autoComplete='false' className="w3-input bordered rounded" type="password" />
                            </div>

                            <div className="w-100 mt-3" data-validate="Type user name">
                                <label className="font-10">Confirm New Password</label>
                                <input onChange={({ target }) => {
                                    const { obj } = this.state;
                                    obj.confirm_password = target.value;
                                    this.setState({ obj });
                                }} autoComplete='false' className="w3-input bordered rounded" type="password" />
                            </div>

                            <div className="w-100 mt-3" data-validate="Type user name">
                                <label className="font-10">Current Password</label>
                                <input onChange={({ target }) => {
                                    const { obj } = this.state;
                                    obj.password = target.value;
                                    this.setState({ obj });
                                }} autoComplete='false' className="w3-input bordered rounded" type="password" />
                            </div>

                            <div className="d-flex flex-row mt-3">
                                <button type="button" className="btn btn-link btn-sm ms-auto" onClick={() => this.setState({ editing: null })}>Cancel</button>
                                {!microTransac && <button type="submit" className="btn btn-success btn-sm" onClick={this.savePassword}>Save</button>}
                                {microTransac && <button type="button" className="btn btn-success btn-sm">Saving...</button>}
                            </div>
                        </form>
                    </Animated>)}

            </div>}

            <div className="p-4" />

        </div >
    }
    render() {

        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>
                    <Menu {...this.state} {...this.props} />

                    <FamilyAddress {...this.props} {...this.state} toggle={() => this.setState({ editing_address: false })} />
                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default FamilyContact;
