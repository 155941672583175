import React from 'react';
import Layout from '../layouts/base.dark';
import { Link } from 'react-router';
import { humanize_user } from '../../helpers';
import BlackOverlay from '../partials/black_overlay';
import { Animated } from 'react-animated-css';
import fakeAuth from '../../fakeAuth';
import SelectableUser from './partials/selectable_user';
import request from 'superagent';
import config from '../../config';
import _ from 'lodash';

class Login extends React.Component {
    state = {
        redirectToReferrer: false,
        mode: 1,
        invalid: false,
        selectedBg: null,
        message: null,
        email: null,
        toggle: true,
        code: ''
    }

    componentWillMount() {
        const { params } = this.props, { referal } = params;
        if (referal) this.props.putReferal(referal);
        if (fakeAuth.isAuthenticated()) {
            this.props.router.push('/dashboard');
        } else {
            // Clear some things
            this.props.teamActions && this.props.teamActions.clearFamilyTeams();
            this.props.teamActions && this.props.teamActions.clearSelectedTeam();
            this.props.familyActions && this.props.familyActions.clearSelectedUser();
            this.props.familyActions && this.props.familyActions.clearFamily();
            this.setState({ email: localStorage.getItem('email') });
        }
    }

    doLogin = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
                email: this.txtEmail.value
            }, () => {

                // Save the email on the localstorage for future usage
                localStorage.setItem('email', this.txtEmail.value);

                // Call API
                request.post(`${config.apiEndpoint}/api/v6/membership/login`)
                    .send({
                        email: this.txtEmail.value,
                        pass: this.txtPassword.value
                    })
                    .then((data) => {
                        if (data.body.success) {

                            // Decrypt User Info
                            data.body.family.forEach(humanize_user);


                            this.setState({ toggle: false }, () => {
                                setTimeout(() => {

                                    if (data.body.family.length > 1) {

                                        this.setState({
                                            mode: 2,
                                            microTransac: false,
                                            users: data.body.family,
                                        }, () => {
                                            this.setState({ toggle: true });
                                        });
                                    } else {
                                        this.setState({ users: data.body.family }, () => {
                                            this.selectUser(_.first(data.body.family));
                                        });
                                    }
                                }, 400)
                            });

                        } else
                            this.setState({ microTransac: false, invalid: true });

                    }, (error) => {
                        this.setState({
                            microTransac: false,
                            invalid: true,
                            message: 'Invalid Email or Password. Please check.', //body.error.message
                        });
                        this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });
        }
    }

    onSendCode = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
                code: this.txtCode.value
            }, () => {
                request.post(`${config.apiEndpoint}/api/v4/membership/code_check`)
                    .send({
                        email: this.state.email,
                        code: this.state.code
                    })
                    .then(data => {
                        this.setState({ toggle: false }, () => {
                            setTimeout(() => {
                                this.setState({
                                    microTransac: false,
                                    toggle: true,
                                    mode: 4,
                                    token: data.body.id
                                });
                            }, 400)
                        });
                    }, reject => {
                        this.setState({
                            microTransac: false,
                            invalid: true
                        });
                    });
            });
        }
    }

    doRecover = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {
                request.post(`${config.apiEndpoint}/api/v4/membership/recover`)
                    .send({ email: this.txtRecover.value, phone_flag: false })
                    .then(data => {
                        if (data.body.success) {

                            this.setState({ toggle: false }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        microTransac: false,
                                        toggle: true,
                                        mode: 3
                                    });
                                }, 400)
                            });
                        } else {
                            this.setState({
                                microTransac: false,
                                invalid: true
                            });
                        }
                    }, reject => {
                        this.setState({
                            microTransac: false,
                            invalid: true,
                        });
                    });
            });
        }

    }

    onReset = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
                new_password: this.txtNewPassword.value
            }, () => {

                request.post(`${config.apiEndpoint}/api/v4/membership/reset`)
                    .send({
                        password: this.txtNewPassword.value,
                        token: this.state.token
                    })
                    .then((data) => {
                        if (data.body.success) {
                            this.setState({ toggle: false }, () => {
                                setTimeout(() => {

                                    // Auto Login ?

                                    // Save the email on the localstorage for future usage
                                    localStorage.setItem('email', this.state.email);

                                    // Call API
                                    request.post(`${config.apiEndpoint}/api/v6/membership/login`)
                                        .send({
                                            email: this.state.email,
                                            pass: this.state.new_password
                                        })
                                        .then((data) => {
                                            if (data.body.success) {

                                                // Decrypt User Info
                                                data.body.family.forEach(humanize_user);


                                                this.setState({ toggle: false }, () => {
                                                    setTimeout(() => {

                                                        if (data.body.family.length > 1) {

                                                            this.setState({
                                                                mode: 2,
                                                                microTransac: false,
                                                                users: data.body.family,
                                                            }, () => {
                                                                this.setState({ toggle: true });
                                                            });
                                                        } else {
                                                            this.selectUser(_.first(data.body.family));
                                                        }
                                                    }, 400)
                                                });

                                            } else
                                                this.setState({ microTransac: false, invalid: true });

                                        }, ({ response: { body } }) => {
                                            this.setState({
                                                microTransac: false,
                                                invalid: true,
                                                message: 'Invalid Email or Password. Please check.', //body.error.message
                                            });
                                            this.props.setDeveloperMessage && this.props.setDeveloperMessage(body.error);
                                        });

                                }, 400)
                            });
                        } else {
                            this.setState({
                                microTransac: false,
                                invalid: true,
                                message: data.body.message
                            });
                        }
                    }, error => {
                        this.setState({
                            microTransac: false,
                            invalid: true,
                        });
                    });


            });
        }
    }

    back = () => this.setState({ toggle: false }, () => {
        setTimeout(() => {
            this.setState({
                mode: 1,
                microTransac: false,
                users: null,
            }, () => {
                this.setState({ toggle: true });
            });
        }, 400)
    });

    selectUser = user => {
        if (user.Token) {
            this.setState({ toggle: false }, () => {
                fakeAuth.authenticate(() => {
                    this.props.form_actions.login({
                        token: user.Token,
                        isAdmin: user.IsLeagueAdmin
                    });
                    setTimeout(() => { this.props.router.push('/dashboard'); }, 500);
                }, user.Token, user.IsLeagueAdmin, user, this.state.users)
            });
        }
    }

    render() {
        const { mode, users, microTransac, email, toggle, code } = this.state;
        return (
            <Layout className="bg-black">
                <section style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <div className="cover h-100 w-100 d-flex justify-content-end" style={{ position: 'relative', background: `url(images/backgrounds/finally.jpg) no-repeat center center` }}>

                        <BlackOverlay />


                        {mode === 1 && <Animated isVisible={(toggle && mode === 1) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                            animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 800, zIndex: 1, borderRadius: 6 }}
                            className="bg-white align-self-center d-flex px-4">

                            <div className="w-100 text-center w3-hide-small">
                                <img src="/images/backgrounds/screenshots/rc.png" className="w-75" />
                            </div>

                            {!this.state.recovering && <div className="w-100 d-flex flex-row px-4">
                                <form key="login-form" className='align-self-center w-100'>
                                    <h1 className="sqwad-font black text-center mt-4">SQWAD SCOUT</h1>
                                    {this.state.error && <div className="p-3 d-flex flex-row font-10 white bg-danger" key="leftActions">
                                        <i className="align-self-center fas fa-exclamation-triangle margin-right-half text-warning font-20" />
                                        <span className="align-self-center">{this.state.error}</span>
                                    </div>}
                                    <div key="email" className="w-100 mt-4" data-validate="Type user name">
                                        <input className="w-100 p-2" defaultValue={email} type="text" name="email" placeholder="Email" ref={(input) => this.txtEmail = input} />
                                    </div>
                                    <div key="pass" className="w-100 mt-4" data-validate="Type password">
                                        <input className="w-100 p-2" type="password" name="pass" placeholder="Password" ref={(input) => this.txtPassword = input} />
                                    </div>
                                    <button type="submit" className="btn btn-info w-100 mt-4" onClick={this.doLogin}>
                                        {!microTransac && <span>Login</span>}
                                        {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                    </button>
                                    <div key="recover-password" className="w-full text-center mt-4" onClick={() => this.setState({ recovering: true, recovering_step: 1 })}>
                                        <span className="txt1" style={{ marginRight: '5px' }}>Forgot</span>
                                        <span className="txt2 underlined jeff_blue">password?</span>
                                    </div>
                                    <div style={{ height: 50 }} />
                                    <Link to="/create_account" className="w-100 btn btn-link text-center">
                                        <span className="txt1" style={{ marginRight: '5px' }}>Not a Member?</span>
                                        <span className="txt2 underlined jeff_blue">Register here.</span>
                                    </Link>
                                    <div style={{ height: 50 }} />
                                </form>
                            </div>}

                            {this.state.recovering && <div className="w-100 d-flex flex-row px-4">

                                {/* RECOVER STEP 1 */}
                                {this.state.recovering_step === 1 && (
                                    <form key="login-form" className='align-self-center w-100'>
                                        <h1 className="sqwad-font black text-center mt-4">RECOVER PASSWORD</h1>
                                        <span>Please enter the Email you used to Register</span>
                                        <div key="email" className="w-100 mt-4" data-validate="Type user name">
                                            <input className="w-100 p-2" defaultValue={email} type="text" name="email" placeholder="Registration Email" ref={(input) => this.txtRecover = input} />
                                        </div>
                                        <button type="submit" className="btn btn-success w-100 mt-4" onClick={this.doRecover}>
                                            {!microTransac && <span>Continue</span>}
                                            {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                        </button>
                                        <div className="btn btn-link w-100 mt-2" onClick={() => this.setState({ recovering: false })}>
                                            <span>Cancel</span>
                                        </div>
                                        <div style={{ height: 120 }} />
                                    </form>
                                )}
                            </div>}

                        </Animated>}


                        {mode === 2 && <Animated isVisible={(toggle && mode === 2) ? true : false} animationIn="fadeInRightBig" animationOut="fadeOutRight" animationOutDuration={400} animationInDuration={400} style={{ width: 600, zIndex: 1, position: 'relative', borderRadius: 6 }} className="bg-white align-self-center d-flex flex-column pr-4">
                            {users && users.map((user, i) => <section key={i} className="selectable-user pointer">
                                <button className="btn w-100 p-0 m-0" onClick={() => this.selectUser(user)}>
                                    <SelectableUser user={user} />
                                </button>
                            </section>)}
                            <button type="button" className="btn btn-link p-2" onClick={this.back}>
                                <i className="fa fa-arrow-left" />
                                {' '}<span>Back</span>
                            </button>
                        </Animated>}


                        {mode === 3 && <Animated isVisible={(toggle && mode === 3) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                            animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 800, zIndex: 1, borderRadius: 6 }}
                            className="bg-white align-self-center d-flex px-4">

                            <div className="w-100 text-center w3-hide-small">
                                <img src="/images/backgrounds/screenshots/recover.png" className="w-75" />
                            </div>

                            <div className="w-100 d-flex flex-row px-4">

                                <form key="login-form" className='align-self-center w-100'>
                                    <h1 className="sqwad-font black text-center mt-4">RECOVER PASSWORD</h1>
                                    <span>A code has been sent. Please put that code here</span>
                                    <div key="email" className="w-100 mt-4" data-validate="Recovery Code">
                                        <input maxLength={4} className="w-100 p-4" type="text" defaultValue={code} name="code" placeholder="XXXX" ref={(input) => this.txtCode = input} />
                                    </div>
                                    <span className="font-8">If you did not received the code, please check on your SPAM folders</span>
                                    <button type="submit" className="btn btn-success w-100 mt-4" onClick={this.onSendCode}>
                                        {!microTransac && <span>Continue</span>}
                                        {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                    </button>
                                    <div className="btn btn-link w-100 mt-2" onClick={() => this.setState({ recovering: false, mode: 1 })}>
                                        <span>Cancel</span>
                                    </div>
                                    <div style={{ height: 120 }} />
                                </form>
                            </div>

                        </Animated>}


                        {mode === 4 && <Animated isVisible={(toggle && mode === 4) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                            animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 800, zIndex: 1, borderRadius: 6 }}
                            className="bg-white align-self-center d-flex px-4">

                            <div className="w-100 text-center w3-hide-small">
                                <img src="/images/backgrounds/screenshots/rc.png" className="w-75" />
                            </div>

                            <div className="w-100 d-flex flex-row px-4">

                                <form key="login-form" className='align-self-center w-100'>
                                    <h1 className="sqwad-font black text-center mt-4">RECOVER PASSWORD</h1>
                                    <span>Type in your new password</span>
                                    <div key="email" className="w-100 mt-4" data-validate="New Password">
                                        <input className="w-100 p-2" type="password" name="pass" placeholder="Password" ref={(input) => this.txtNewPassword = input} />
                                    </div>
                                    <span>{this.state.token}</span>
                                    <button type="submit" className="btn btn-success w-100 mt-4" onClick={this.onReset}>
                                        {!microTransac && <span>Continue</span>}
                                        {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                    </button>
                                    <div className="btn btn-link w-100 mt-2" onClick={() => this.setState({ recovering: false, mode: 1 })}>
                                        <span>Cancel</span>
                                    </div>
                                    <div style={{ height: 120 }} />
                                </form>
                            </div>

                        </Animated>}


                        <span className="sqwad-font white font-14 w3-hide-small" style={{ position: 'absolute', top: 20, left: 20 }}>SQWAD SCOUT</span>

                        <Animated animationInDelay={500} animationInDuration={300} animationIn="fadeInLeft" isVisible={true} animateOnMount={true} className="d-flex flex-row justify-content-center" style={{ position: 'absolute', bottom: 20, left: 20 }}>
                            <a className="align-self-center" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/the-sqwad/id1506954207"><img src="/images/icons/appstore.png" alt="" style={{ height: 30 }} /></a>

                            {/*<img alt="" className='ms-2 align-self-center' style={{ height: 70, width: 70 }} src="https://api.sportslogic.net/api/v5/qrcodes/url/10" />*/}

                            <a className="ms-2 align-self-center" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.trycatch.squad.app"><img src="/images/icons/playstore.png" alt="" style={{ height: 30 }} /></a>

                        </Animated>
                    </div>

                </section>
            </Layout>
        )
    }
}

export default Login;
