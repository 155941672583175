import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import auth from './auth';
import cart from './cart';
import coach_profile from './coach_profile';
import coaches from './coaches';
import collaborators from './collaborators';
import communities from './communities';
import countries from './countries';
import custom_pages from './custom_pages';
import disclaimer from './disclaimer';
import emails from './emails';
import evaluation from './evaluation';
import evaluations from './evaluations';
import family from './family';
import folder from './folder';
import folders from './folders';
import follow_levels from './follow_levels';
import follow_seasons from './follow_seasons';
import followees from './followees';
import form from './form';
import information from './information';
import is_cart_open from './isCartOpen';
import league from './league';
import leagues from './leagues';
import loading from './loading';
import match from './match';
import notes from './notes';
import penalties from './penalties';
import phones from './phones';
import player from './player';
import players from './players';
import plans from './plans';
import pouch from './pouch';
import prospects from './prospects';
import public_matches from './public_matches';
import questionaries from './questionaries';
import registrations from './registrations';
import roster from './roster';
import scout_match from './scout_match';
import seasons from './seasons';
import states from './states';
import subscriptions from './subscriptions';
import team from './team';
import teams from './teams';
import topics from './topics';
import user from './user';
import video from './video';
import videos from './videos';

const rootReducer = combineReducers({
    cart,
    coaches,
    coach_profile,
    collaborators,
    communities,
    countries,
    custom_pages,
    disclaimer,
    emails,
    evaluation,
    evaluations,
    family,
    folder,
    folders,
    follow_levels,
    follow_seasons,
    followees,
    form,
    information,
    is_cart_open,
    league,
    leagues,
    loading,
    match,
    notes,
    penalties,
    phones,
    player,
    players,
    plans,
    pouch,
    prospects,
    public_matches,
    questionaries,
    registrations,
    roster,
    routing: routerReducer,
    scout_match,
    seasons,
    states,
    subscriptions,
    team,
    teams,
    topics,
    user,
    video,
    videos,
});

export default rootReducer;
