import React from 'react';
import { Link } from 'react-router';
import fakeAuth from '../../fakeAuth';

class NavBar extends React.Component {

    state = {
        user: null
    }

    // Lifecycle
    componentWillMount() {
        this.setState({
            user: fakeAuth.getUser()
        });
    }


    logout = () => {
        const { router } = this.props;
        fakeAuth.signout(() => {
            this.props.form.logout && this.props.form.logout();
            router.push('/login');
        });
    }

    popCart = () => {
        this.props.cart_actions && this.props.cart_actions.open();
    }

    render() {
        const { className, nbs, form, league, cart } = this.props, { user } = this.state;
        const cart_filtered = _.filter(cart, (item) => {
            return item.selectedLeague && league && item.selectedLeague.IdLeague === league.IdLeague;
        });
        return (
            <section id="top" className="ms-auto w3-hide-small">
                <div className="d-flex flex-row h-100 toBold syncopate" style={{ fontSize: '13px' }}>
                    <Link to="/dashboard" className={`ms-4 align-self-center font-10 ${className} ${nbs === 'managers' ? 'white' : 'white'}`}>HOME</Link>
                    <a onClick={this.logout} className={`font-10 ms-4 orange align-self-center me-4 pointer`}>LOGOUT</a>
                    <Link to="/family" className="me-4 align-self-center"><img alt="" src={user.UserImage} className="smallPic" /></Link>
                    { /* Cart button */}
                    {cart_filtered && cart_filtered.length > 0 && league && <button key="cart-button" className="btn btn-success square" style={{ width: 'auto' }} onClick={this.popCart}>
                        <i className="icon-shopping_cart white bigest-font montserrat">{cart_filtered.length}</i></button>}
                </div>
            </section>
        );
    }
}

export default NavBar;