import React from 'react';
import ReactDOM from 'react-dom';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId, validateEmail } from '../../../helpers';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';
import config from '../../../config';
import request from 'superagent';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';

class AddFamily extends React.Component {

    state = {
        obj: {
            country: null,
            CountryCode: _.first(config.country_codes)
        },
    }

    componentWillMount() {
        const { user } = this.props;
        if (user) {
            const { obj } = this.state;
            obj.IdUser = user.IdUser;
            obj.email = user.EmailAddress;
            obj.phoneNumber = user.IdPhoneNumber;
            obj.phoneWithMask = user.PhoneNumber;
            obj.nameFirst = user.NameFirst;
            obj.nameLast = user.NameLast;
            obj.dob = moment(user.DateBirth).utc().format('MMDDYYYY');
            obj.idGender = user.IdGender;
            obj.CountryCode = _.find(config.country_codes, c => c.value === user.CountryCode) || obj.CountryCode;
            this.setState({ obj, bootstrapped: true });
        }
    }

    onSave = (e) => {

        e && e.preventDefault && e.preventDefault();

        const { obj } = this.state;
        obj.email = this.txtEmail.value;
        obj.phoneNumber = (ReactDOM.findDOMNode(this.txtPhone).value);
        obj.phoneWithMask = this.txtPhone.inputElement.value;
        obj.nameFirst = this.txtNameFirst.value;
        obj.nameLast = this.txtNameLast.value;
        obj.dob = moment(this.txtDob.inputElement.value, 'MM/DD/YYYY').utc().format();


        if (!validateEmail(obj.email)) {
            alert('Invalid email, please check');
            return;
        }

        if (obj.email &&
            obj.phoneNumber &&
            obj.nameFirst &&
            obj.nameLast &&
            obj.dob) {
            this.setState({ microTransac: true }, () => {
                request[obj.IdUser ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/family/member/full${obj.IdUser ? `/${obj.IdUser}` : ''}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sqwadrc.authtoken')}`)
                    .send({
                        NameFirst: obj.nameFirst,
                        NameLast: obj.nameLast,
                        IdGender: obj.idGender,
                        DateBirth: obj.dob,
                        PhoneNumber: obj.phoneNumber,
                        EmailAddress: obj.email,
                        CountryCode: obj.CountryCode.value
                        /*Shirt,
                        Short,
                        JerseyNumber,
                        UserImage,
                        IdVenmo,
                        MedicalNotes*/
                    })
                    .then((data) => {
                        if (data.body.success) {
                            // TODO: we need to re-call api to populate family
                            this.props.scout_actions && this.props.scout_actions.fetch_family();
                            this.props.toggle();
                        } else {
                            alert('Sorry, this email address is unavailable');
                            this.setState({ microTransac: false });
                        }
                    });
            });
        } else {
            alert('Please, complete the required fields');
        }
    }

    onCountryCode = (c) => {
        const { obj } = this.state;
        obj.CountryCode = c;
        this.setState({ obj });
    }

    onGender = i => {
        const { obj } = this.state;
        obj.idGender = i;
        this.setState({ obj });
    }

    render() {
        const { colors, obj, microTransac, } = this.state, { user } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.creating_family ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-column w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <div className="w3-content mx-auto d-flex flex-column black pl-4">
                        <div className="w3-container bg-white p-4 ms-4">
                            <div className="w-100 d-flex flex-row px-4">
                                <form key="login-form" className='align-self-center w-100'>
                                    <h2 className="sqwad-font black text-center mt-4 line1">ADD FAMILY MEMBER</h2>

                                    <div className="w-100 mt-4">
                                        <label className="font-10">First Name</label>
                                        <input className="w3-input bordered rounded" type="text" defaultValue={obj.nameFirst} name="firstname" placeholder="First name" ref={(input) => this.txtNameFirst = input} />
                                    </div>

                                    <div className="w-100 mt-3">
                                        <label className="font-10">Last Name</label>
                                        <input className="w3-input bordered rounded" type="text" defaultValue={obj.nameLast} name="lastname" placeholder="Last name" ref={(input) => this.txtNameLast = input} />
                                    </div>

                                    <div className="w-100 mt-3" data-validate="Type user name">
                                        <label className="font-10">Email</label>
                                        <input className="w3-input bordered rounded" type="text" defaultValue={obj.email} name="email" placeholder="Email" ref={(input) => this.txtEmail = input} />
                                    </div>

                                    <div className="mt-3 w-100">
                                        <label className="font-10">Gender</label>
                                        <div className="rounded bordered w-100 text-center">
                                            <div className="d-flex">
                                                <div className="w-100 text-center d-flex flex-column p-2" onClick={() => this.onGender(1)}>

                                                    <div style={{ width: 50, height: 50, border: `2px solid ${obj.idGender === 1 ? '#013469' : 'gray'}`, borderRadius: 25, overflow: 'hidden', backgroundColor: obj.idGender === 1 ? '#013469' : 'transparent' }} className="text-center m-auto d-flex flex-column justify-content-end">
                                                        <span className={`icon-man icon-men ${obj.idGender === 1 ? 'white' : 'gray'} font-40`}>
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                            <span className="path3" />
                                                            <span className="path4" />
                                                            <span className="path5" />
                                                            <span className="path6" />
                                                            <span className="path7" />
                                                            <span className="path8" />
                                                            <span className="path9" />
                                                            <span className="path10" />
                                                            <span className="path11" />
                                                            <span className="path12" />
                                                            <span className="path13" />
                                                            <span className="path14" />
                                                            <span className="path15" />
                                                            <span className="path16" />
                                                            <span className="path17" />
                                                        </span>
                                                    </div>
                                                    <span className="font-10 black toUpper sqwad-font">Male</span>

                                                </div>
                                                <div className="w-100 text-center d-flex flex-column p-2" onClick={() => this.onGender(2)}>
                                                    <div style={{
                                                        width: 50, height: 50, border: `2px solid ${obj.idGender === 2 ? '#013469' : 'gray'}`, borderRadius: 25, overflow: 'hidden',
                                                        backgroundColor: obj.idGender === 2 ? '#013469' : 'transparent'
                                                    }} className="text-center m-auto d-flex flex-column justify-content-end">
                                                        <span className={`icon-woman ${obj.idGender === 2 ? 'white' : 'gray'} font-40`}>
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                            <span className="path3" />
                                                            <span className="path4" />
                                                            <span className="path5" />
                                                            <span className="path6" />
                                                            <span className="path7" />
                                                            <span className="path8" />
                                                            <span className="path9" />
                                                            <span className="path10" />
                                                            <span className="path11" />
                                                            <span className="path12" />
                                                            <span className="path13" />
                                                            <span className="path14" />
                                                            <span className="path15" />
                                                        </span>
                                                    </div>
                                                    <span className="font-10 black toUpper sqwad-font">Female</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <label className="font-10">Date of Birth (MM/DD/YYYY)</label>
                                        <MaskedInput placeholder="DOB" id="txtDoB" defaultValue={obj.dob} ref={(i) => this.txtDob = i} guide={true} className="w3-input bordered rounded font-12" mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />
                                    </div>


                                    <div className={`mt-3`}>
                                        <label className="font-10">Phone Number</label>
                                        <div className="d-flex">

                                            <Dropdown isOpen={this.state.isCountryCodeOpen ? true : false} toggle={() => this.setState({ isCountryCodeOpen: !this.state.isCountryCodeOpen })} className="w3-input bordered rounded p-0" style={{ width: 200 }}>
                                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="h-100 w-100 text-left d-flex">
                                                    <img src={obj.CountryCode ? obj.CountryCode.image : ''} alt="" className="align-self-center me-1" style={{ height: 20 }} />
                                                    <span className="black align-self-center font-12">{obj.CountryCode ? obj.CountryCode.value : 'Code'}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {config.country_codes.map((c, i) => <DropdownItem key={i} onClick={() => this.onCountryCode(c)}>
                                                        {c.title}
                                                    </DropdownItem>)}
                                                </DropdownMenu>
                                            </Dropdown>
                                            <div className="w-100 ms-2">
                                                <MaskedInput guide={true} ref={(i) => this.txtPhone = i} className="w3-input bordered rounded font-12 w-100" placeholder="Phone Number (*)" defaultValue={obj.phoneNumber}
                                                    mask={obj.CountryCode.mask} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ height: 50 }} />
                                </form>
                            </div>


                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Save' : 'Save'}</button>
                        </div>
                    </div>
                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default AddFamily;