import React from 'react';
import _ from 'lodash';
import { formatPrice } from '../../../../helpers';

class CheckList extends React.Component {
    state = {
        answer: null
    }

    toggle = (i) => {
        const { question } = this.props;
        question.Answers[i].checked = !question.Answers[i].checked;
        let built_answer = _.filter(question.Answers, s => s.checked);
        question.answer = { ..._.first(question.Answers, (a) => { return a.checked; }) };
        question.built_answer = built_answer.length ? {
            text: _.map(built_answer, a => a.AnswerText).join(' AND/OR '),
            value: _.chain(built_answer).map(a => a.Amount).reduce((memo, num) => memo + num).value(),
            options: _.map(built_answer, a => a)
        } : null;

        /*

        let answer = built_answer.length ? {
            text: _.map(built_answer, a => a.answer.AnswerText).join(' AND/OR '),
            value: _.chain(built_answer).map(a => a.answer.Amount).reduce((memo, num) => memo + num).value(),
            options: _.map(built_answer, a => a.answer)
        }
        */
        this.forceUpdate();
    }

    render() {
        const { question } = this.props;
        return <div className="mx-3 margin-top-half d-flex flex-column">

            {/* QUESTIONS */}
            <div className="d-flex flex-row">
                <span className="text-left w-100 align-self-center">{question.QuestionText}</span>
                {question.Required && <i className="ms-auto fa fa-asterisk red align-self-center" />}
            </div>

            {/* ANSWERS */}
            {question.Answers.map((a, i) => <button key={i} className="btn btn-default margin-right margin-top-half d-flex flex-row" onClick={() => this.toggle(i)}>
                <i className={`margin-right align-self-center ${a.checked ? 'fa fa-check-circle green' : 'far fa-circle'}`} />
                <span className="text-left w-100 align-self-center">{a.AnswerText + (a.Amount !== 0 ? ' (' + formatPrice(a.Amount * 100) + ')' : '')}</span>
            </button>

            )}
            <div style={{ height: 10 }} />
        </div>
    }
}

export default CheckList;