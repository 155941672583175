import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import fakeAuth from '../../fakeAuth';


class Registration extends React.Component {
    state = {
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.register.mode') || 1, 10),
        isMobile: window.innerWidth <= 600
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchLeaguesWithSeasons();
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }
    componentWillReceiveProps = nextProps => {
        const { leagues } = nextProps;        
        this.setState({ leagues });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 600 });
    };
    renderCommon = () => {
        const { mode } = this.state, { follow_seasons } = this.props;

        return <div className="w3-content px-0 container pt-4">
            <Link to="/registration/register" className="card p-4 shadow">
                <div>
                    <i className="icon-log-in green font-30" /> <span className="font-30 montserrat ms-2">Register</span>
                </div>
                <span className="montserrat">Search for one or more of the upcoming Tournaments and Seasons</span>
            </Link>
            <div className="card p-4 shadow mt-4">
                <div>
                    <i className="icon-coin-dollar orange font-30" /> <span className="font-30 montserrat ms-2">Payments</span>
                </div>
                <span className="montserrat">Be solvent with your accounts on your registered Tournaments and Seasons</span>
            </div>
        </div>
    }
    render() {

        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {this.state.isMobile ? (
                        // MOBILE
                        <div className="w3-container w3-hide-medium w3-hide-large">
                            <div style={{ height: 65 }} />
                            {this.renderCommon()}
                        </div>
                    ) : (
                        // DESKTOP
                        <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                            <div style={{ height: 65 }} />
                            {this.renderCommon()}
                        </div>
                    )}
                    <Menu {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default Registration;
