import React from 'react';
import moment from 'moment';
import Hls from "hls.js";
import config from '../../config';
import { Animated } from 'react-animated-css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
/*import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon,
    FacebookIcon,
} from "react-share";*/

class VideoPlayer extends React.Component {
    state = {
        microTransac: false,
        seasons: [],
        bootstrapped: false,
        clipping: false
    }
    componentWillMount() {
        const { video, seasons, height = 240 } = this.props;
        this.setState({ video, seasons, height });
    }
    componentWillReceiveProps = nextProps => {
        const { video } = nextProps;
        this.setState({ video }, () => {
            if (this.state.video) {
                const video_player = this.player;
                const hls = new Hls();
                const url = `${config.wowzaEndpoint}/dispatcher/${this.state.video.description}/playlist.m3u8`;
                hls.loadSource(url);
                hls.attachMedia(video_player);
                hls.on(Hls.Events.MANIFEST_PARSED, function () { });
                this.setState({ bootstrapped: true });
            }
        });
    }

    clip = (time) => {
        this.player.pause && this.player.pause();
        this.props.onClip && this.props.onClip({ middle: this.player.currentTime, length: parseInt(time, 10) });
    }

    onClipping = () => this.setState({ clipping: !this.state.clipping }, () => {
        this.player.pause && this.player.pause();
    });

    render() {
        const { video, clipping } = this.state;

        return (
            <section>
                <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.video ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                    <section className="bg-whiteish d-flex flex-row w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                        {video && <div className="tile w-100">
                            <div className="w-100 p-2">

                                <video
                                    poster={video.thumbnail}
                                    ref={player => (this.player = player)}
                                    controls={true}
                                    width="100%"
                                    height="600"
                                    style={{ backgroundColor: 'black' }}
                                />

                                <section>
                                    <div className="d-flex flex-row p-2">
                                        <span className="align-self-center font-20 black" onClick={this.props.onTag}>{video && video.name}</span>
                                        {/*<i className="ms-auto far fa-eye font-12 align-self-center" />
                            <span className="ms-2 align-self-center">319</span>
                            <span className="ms-2 align-self-center">|</span>*/}
                                        <span className="ms-auto align-self-center">{moment(video.createDate.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</span>
                                        {/*{(video.isClip || video.isHighlight) && <span className="ms-2 align-self-center">|</span>}

                                        {(video.isClip || video.isHighlight) && !this.state.sharing && <span className="ms-2 align-self-center" onClick={() => this.setState({ sharing: !this.state.sharing })}><i className="fas fa-share" /> Share</span>}
                                        this.state.sharing && <div className="d-flex flex-row justify-content-end align-self-center ml-3">
                                <TwitterShareButton className="mr-2 align-self-start" title="Team Brochure" url={shareUrl}>
                                    <TwitterIcon size={30} round={true} />
                                </TwitterShareButton>
                                <WhatsappShareButton className="mr-2 align-self-start" title={`Check out this video`} url={shareUrl}>
                                    <WhatsappIcon size={30} round={true} />
                                </WhatsappShareButton>
                                <FacebookShareButton className="mr-2 align-self-start" quote={`Check out this video`} url={shareUrl}>
                                    <FacebookIcon size={30} round={true} />
                                </FacebookShareButton>
                                <div className="w-auto d-flex flex-column">
                                    <input className={`${this.state.copySuccess ? 'bg-success' : ''} form-control text-input w-auto`} disabled="disabled" ref={(i) => this.txtClipboard = i} value={shareUrl} />
                                    {this.state.copySuccess && <span className="black font-10">Link copied in your clipboard!</span>}
                                </div>
                                <CopyToClipboard text={shareUrl}
                                    onCopy={() => this.setState({ copySuccess: true })}>
                                    <button className="btn btn-link align-self-start"><i className="fas fa-clipboard blue" /></button>
                                </CopyToClipboard>
                        </div>*/}
                                    </div>
                                </section>

                            </div>
                        </div>}

                    </section>
                    <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                        <i className="fas fa-times black font-30 align-self-center" />
                    </div>
                </Animated>
            </section>
        )
    }
}
export default VideoPlayer;