function league(state = [], action) {
    switch (action.type) {
        case 'LEAGUE/SET':            
            return action.league || state;
        case 'LEAGUE/CLEAR':
            return null;
        default:
            return state;
    }
}

export default league;
